<template>
  <button
      :disabled="disabled"
      type="button"
      v-on:click="$emit('clicked')"
      :class="[
          'rounded-md',
          'font-montserrat',
          'drop-shadow-lg',
          backgroundColor,
          fontColor,
          'text-sm',
          'font-semibold',
          'p-2',
          'h-full',
          'w-full',
          'disabled:bg-gray-400',
          'disabled:cursor-not-allowed']">

    {{ buttonText }}
  </button>
</template>

<script type="ts">

export default {
  props: {
    buttonText: {
      type: String,
      default: 'Continue'
    },
    backgroundColor: {
      type: String,
      default: 'bg-green'
    },
    fontColor: {
      type: String,
      default: 'text-white'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ["clicked"]
}
</script>