<template>
    <nav class="bg-white w-full flex justify-center sticky top-0 z-10">
        <div class="container px-4 sm:px-0 sm:w-3/4">
            <div class="flex items-center justify-between">
                <a href="/dashboard" class="flex cursor-pointer flex-row items-center space-x-2">
                    <img src="/img/logo.svg" class="h-32" alt="Soopa Connect">
                </a>
                <div class="flex-grow flex justify-center">
                    <p class="font-bold text-lg sm:text-2xl font-montserrat" :class="{ 'mr-[10%] sm:mr-[22%]': !showCart }">{{ pageHeader }}</p>
                </div>
                <div v-if="showCart" class="flex flex-row items-center space-x-2 relative">
                    <CartButton :cartQuantity="cartItemsLength" @click="toggleDropdown"/>
                    <div v-if="dropdownVisible" class="dropdown z-10 bg-white font-montserrat">
                        <div v-if="cartItems && cartItemsLength > 0">
                            <ul>
                                <li v-for="(item, index) in cartItems" :key="index">
                                    <div class="flex py-2 items-center justify-between">                                        
                                        <p class="font-bold">{{ itemDescriptions[item.catalogueEntryGuid] }}</p>
                                        <div class="flex space-x-5 justify-between items-center">
                                            <img v-if="item.quantity > 1" class="w-4 cursor-pointer" @click="decreaseItemQuantity(item)" src="../../../../public/img/minus.svg" alt="reduceQuantity">
                                            <img v-else class="w-4" src="../../../../public/img/disable_minus.svg" alt="reduceQuantity">
                                            <span class="w-4 text-center">{{ item.quantity }}</span>
                                            <img class="w-4 cursor-pointer" @click="increaseItemQuantity(item)" src="../../../../public/img/plus.svg" alt="increaseQuantity">
                                            <img @click="removeCartItem(item)" class="w-4 cursor-pointer" src="../../../../public/img/bin_grey.svg">
                                        </div>
                                    </div>
                                </li>
                                <PrimarySubmit @click="navigateToCheckout()" buttonText="Go to Checkout" class="text-white w-full" />
                            </ul>
                        </div>
                        <div v-else>
                            <p class="text-center">No items in cart</p>
                        </div>
                    </div>
                </div>
                <div class="flex flex-row items-center space-x-2">
                    <!-- This is an empty div to balance the logo on the left -->
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
import CartButton from '../AQuarks/CartButton.vue'
import PrimarySubmit from '../AQuarks/PrimarySubmit.vue'
import { useSessionStore } from "@/areas/sessions/stores/sessionStore";
import { useCatalogueStore} from "@/areas/catalogues/stores/catalogueStore";
import {defineComponent, onMounted, onUnmounted, onBeforeMount, ref, watch } from 'vue';
import bus from '../../../bus'

export default {
    components: {
        CartButton,
        PrimarySubmit
    },
    props: {
        pageHeader: {
            type: String            
        },
        showCart: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            dropdownVisible: false,
        };
    },
    setup() {
        const sessionStore = useSessionStore();
        const catalogueStore = useCatalogueStore();

        const cartItems = ref([]);
        const cartItemsLength = ref(0);

        const init = async () => {
            let _initComplete = false;
            await validateSession();
            _initComplete = true
        };

        const validateSession = async () => {
            const session = await sessionStore.getOrCreateSession()
          
            if(session == null)
            {
                return;
            }
            
            cartItems.value = session.items;
            
            let totalQuantity = 0;
            
            for(let item of cartItems.value) {
                totalQuantity += item.quantity;
            }

            cartItems.value.sort((a, b) => a.catalogueEntryGuid.localeCompare(b.catalogueEntryGuid));
            cartItemsLength.value = totalQuantity;
                return session;
            };

        const itemDescriptions = ref({});

        watch(cartItems, async (newCartItems) => {
            for (let item of newCartItems) {
                itemDescriptions.value[item.catalogueEntryGuid] = await getCatalogueItemDesc(item.catalogueEntryGuid);
            }
        });

        async function getCatalogueItemDesc(guid) {
            const catalogueItem = await catalogueStore.getCatalogueEntry(guid);
            
            return catalogueItem.sku.descriptions[0].descValue;
        }

        onMounted(() => {
            bus.on('cart-updated', async () => {
                await validateSession();
            });
        });

        onUnmounted(() => {
            bus.off('cart-updated');
        });

        onBeforeMount(async () => {
            await init();
        });

        return {validateSession, itemDescriptions, sessionStore, cartItems, cartItemsLength}
    },
    methods: {
        async toggleDropdown() {
            this.dropdownVisible = !this.dropdownVisible;            
        },
        async removeCartItem(item) {
            const session = await this.validateSession();
            const response = await this.sessionStore.deleteCartItem(session.guid, item.catalogueEntryGuid)
            await this.validateSession();
            bus.emit('cart-updated');
        },
        async navigateToCheckout() {
            this.$router.push('/checkout')
        },
        async increaseItemQuantity(item) {
            const session = await this.validateSession();
            const newQuantity = item.quantity + 1;
            const response = await this.sessionStore.updateCart(item.guid, session.guid, newQuantity);
            await this.validateSession();
            bus.emit('cart-updated');
        },
        async decreaseItemQuantity(item) {
            const session = await this.validateSession();
            const newQuantity = item.quantity - 1;
            const response = await this.sessionStore.updateCart(item.guid, session.guid, newQuantity);
            await this.validateSession();
            bus.emit('cart-updated');
        }
    }
}
</script>

<style scoped>
.dropdown {
    position: absolute;
    top: 55px;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    width: 500px;
    max-width: 300px;
    z-index: 100;
    right: 0;
}
</style>
