import axiosInstance from "@/axiosConfig";
import {AddressDto} from '../../users/dtos/addressDto';
import {ContactDto} from '../../users/dtos/contactDto';
import {SessionDto} from "@/areas/sessions/dtos/sessionDto";
import {OperationResult} from "@/helpers/responses/models/operationResult";
import {Operations} from "@/helpers/responses/operations";
import {ProblemDetails} from "@/helpers/responses/models/problemDetails";

export class SessionProxy {

    public async SessionsByState(sessionStateEnum: string): Promise<OperationResult<SessionDto[]>> {
        const uri = `/sessions/list/${sessionStateEnum}`

        try {
            const response = await axiosInstance.get<SessionDto[]>(uri)

            return Operations.ToSuccessResponse(response.data)
        } catch (error : any) {

            if (error.response && error.response.status != 500) {
                const response = error.response

                const problemDetails: ProblemDetails = {
                    detail: response.data.detail || "Unknown error",
                    status: response.status,
                    type: response.data.type,
                }

                return Operations.ToErrorResponseFromProblemDetails(problemDetails)
            }

            return Operations.ToErrorResponse("RequestFailure")
        }
    }

    public async GetOrCreate() {
        const uri = '/sessions'

        try {
            const response = await axiosInstance.put(uri)

            return response.data;
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    public async getOpenSessions() {
        const uri = '/sessions/list'

        try {
            const response = await axiosInstance.get(uri)

            return response.data;
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    public async getSessionByGuid(sessionGuid: string) {
        const uri = `/sessions/${sessionGuid}`

        try {
            const response = await axiosInstance.get(uri)

            return response.data;
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    public async fetchOrderHistoryList() {
        const uri = 'reports/sessions/history'

        try {
            const response = await axiosInstance.get(uri)

            return response.data;
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    public async AddToCart(sessionGuid: string, itemGuid: string) {
        const uri = "/sessions/cart";
        try {
            const body = {
                sessionGuid: sessionGuid,
                itemReference: itemGuid,
                quantity: 1
            };
            const jsonString = JSON.stringify(body);
            const response = await axiosInstance.post(uri, jsonString, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            return response.data;
        } catch (error) {
            console.error(error);
            return null;
        }
    }
    
    public async Complete(sessionGuid: string): Promise<OperationResult<SessionDto>> {
        const uri = `/sessions/${sessionGuid}/complete`
        
        const body = {
            sessionGuid: sessionGuid
        }
        
        try {
            const response = await axiosInstance.put<SessionDto>(uri, body)

            return Operations.ToSuccessResponse(response.data)
        } catch (error : any) {

            if (error.response && error.response.status != 500) {
                const response = error.response

                const problemDetails: ProblemDetails = {
                    detail: response.data.detail || "Unknown error",
                    status: response.status,
                    type: response.data.type,
                }

                return Operations.ToErrorResponseFromProblemDetails(problemDetails)
            }

            return Operations.ToErrorResponse("RequestFailure")
        }
    }

    public async DeleteCartItem(sessionGuid: string, itemGuid: string) {
        const uri = "/sessions/item"
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json'
                },
                data: {
                    itemReference: itemGuid,
                    sessionGuid: sessionGuid
                }
            };

            const response = await axiosInstance.delete(uri, config);
            return response.data;
        } catch (error) {
            console.error(error)
            return null;
        }

    }

    public async UpdateCart(itemGuid: string, sessionGuid: string, quantity: Number) {
        const uri = "/sessions/cart";
        try {
            const body = {
                itemGuid: itemGuid,
                sessionGuid: sessionGuid,
                quantity: quantity
            };
            const jsonString = JSON.stringify(body);
            const response = await axiosInstance.put(uri, jsonString, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            return response.data;
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    public async ListCartItems() {
        const uri = `/sessions`;
        try {
            const response = await axiosInstance.get(uri);
            return response.data;
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    public async CreateDelivery(address: AddressDto, emailContact: ContactDto, mobileContact: ContactDto, sessionGuid: string, externalId: string) {
        const uri = `/sessions/delivery`;
        try {
            const body = {
                address: {
                    id: address.id,
                    street: address.street,
                    city: address.city,
                    suburb: address.suburb,
                    province: address.province,
                    postalCode: address.postalCode,
                    latitude: address.latitude,
                    longitude: address.longitude
                },
                emailContact: {
                    id: emailContact.id,
                    contactTypeEnum: emailContact.contactTypeEnum,
                    value: emailContact.value
                },
                mobileContact: {
                    id: mobileContact.id,
                    contactTypeEnum: mobileContact.contactTypeEnum,
                    value: mobileContact.value
                },
                sessionGuid: sessionGuid,
                externalId: externalId
            };
            const response = await axiosInstance.post(uri, body, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            return response.data;
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    public async CreatePaymentIntent(sessionGuid: string) {
        const uri = `sessions/payments`;

        try {
            const body = {
                sessionGuid: sessionGuid,
                returnUri: `${process.env.VUE_APP_BASE_APP_URL}/payment/success/${sessionGuid}`,
                cancelUri: `${process.env.VUE_APP_BASE_APP_URL}/payment/cancel/${sessionGuid}`
            }

            // Store the sessionGuid in localStorage
            localStorage.setItem('sessionGuid', sessionGuid);

            const response = await axiosInstance.post(uri, body, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            return response.data;
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    public async CancelOrder(sessionGuid: string) {
        const uri = `sessions/payments/${sessionGuid}/cancel`;

        try {
            const response = await axiosInstance.delete(uri);
            return response.data;
        } catch (error) {
            console.error(error);
            return null;
        }
    }

}