import {defineStore} from 'pinia'
import { UserProxy } from '@/areas/users/dtos/userProxy'

export const useUserStore = defineStore({
    id: "user",
    state: () => ({
        updated_at: new Date(),
        proxy: new UserProxy()
    }),
    actions: {
        async getUserAddress() {
            const address = await this.proxy.GetUserAddress();
            if(!address) {
                return null;
            }
            return address;
        },

        async getUserContact() {

            const contact = await this.proxy.GetUserContact();
            if(!contact) {
                return null;
            }
            return contact;
        },

        async getUser() {
            const user = await this.proxy.GetUser()

            if(!user) {
                return null;
            }
            return user;
        }

    }
})