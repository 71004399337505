<template>
  <div class="flex flex-col items-center justify-center min-h-screen bg-blue-100">
    <img class="mb-8 mt-12 sm:mt-0 drop-shadow-lg w-36" src="/img/logo.svg" alt="Your Company Logo">

    <h1 class="text-3xl font-montserrat font-bold mb-2">Sign Up</h1>
    <p class="text-md sm:text-xl mx-auto font-montserrat mb-8">Open your Wowza Plus Reseller Account</p>

    <div
        class="flex flex-col bg-white font-montserrat w-[85%] sm:w-[60%] xl:w-[30%] shadow-md rounded-lg px-8 pt-6 pb-4 sm:pb-8 mb-4">
      <div class="flex flex-wrap">
        <input v-model="firstName" class="mb-6 w-full sm:w-[45%] text-center border-b mx-auto border-black" type="text"
               placeholder="First Name" required>
        <input v-model="lastName" class="mb-6 w-full sm:w-[45%] text-center border-b mx-auto border-black" type="text"
               placeholder="Last Name" required>
        <input v-model="email" class="mb-6 w-full sm:w-[95%] text-center border-b mx-auto border-black" type="email"
               placeholder="Email" required>
        <input v-model="cellphone" @input="touched.cellphone = true"
               class="mb-6 w-full sm:w-[95%] text-center border-b mx-auto border-black" type="tel"
               placeholder="Cellphone Number" required>
               <p v-if="!validateCellNumber() && touched.cellphone" class="mx-auto -mt-5 text-red">Invalid phone number</p>
        <input v-model="referralCode" disabled class="mb-6 w-full sm:w-[95%] text-center border-b mx-auto border-black"
               type="tel" placeholder="Referral Code">
      </div>
      <div class="flex flex-row items-center justify-center mb-4 text-center">
        <label for="terms" class=" text-sm sm:text-md align-middle">
          By ticking the box below, I Accept the
          <a @click="viewTermsConditions()"
          href="#"
          class="text-blue-500 underline">
            T's & C's
          </a>, 
          <a @click="viewCodeOfConduct()" href="#" class="text-blue-500 underline">
          Code of Conduct
          </a> 
          and 
          <a @click="viewResellerCommission()" href="#" class="text-blue-500 underline">
          Reseller Program Commission
          </a>
          <div class="block mt-4">
            <input type="checkbox" id="terms" v-model="acceptedTerms" class="mr-2 align-middle">
            I Accept
          </div>
        </label>
      </div>
      <p v-if="!acceptedTerms" class="mx-auto text-red">{{ errorMessage }}</p>

      <button :disabled="showPopup" @click="createUser()"
              class="bg-green mx-auto w-3/4 md:w-[50%] text-white font-bold py-2 px-4 rounded">Sign Up
      </button>
      <div class="flex flex-row cursor-pointer space-x-2 items-center mt-4 sm:mt-6 mx-auto">
        <img src="/img/back_to_login.svg" class="w-2.5">
        <a @click="logout" class="text-black">Back to login</a>
      </div>
    </div>


  </div>

  <div v-show="showPopup" class="fixed inset-0 z-50 overflow-y-auto bg-black bg-opacity-50">
    <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
      <div
          class="relative transform font-montserrat overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
        <div class="mt-3 text-center sm:mt-5">
          <div class="mt-2">
            <p class="text-black">Thank you for signing up. In order to use the platform, you will need to receive a
              referral link from one of our referral partners.</p>
            <p>If you would like to apply for a referral programme,
              please complete the application form here:</p><a class="underline"
                                                               href="https://www.soopaconnect.com/resellers-signup">https://www.soopaconnect.com/resellers-signup</a>
          </div>
        </div>
        <div class="mt-5 sm:mt-6">
          <button @click="logout()" type="button"
                  class="inline-flex w-full justify-center rounded-md bg-main-blue px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2">
            Logout
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {useUserStore} from "@/areas/users/stores/userStore";
import router from "@/router";
import keycloak from "@/keycloak";
import axiosInstance from '../axiosConfig';

export default {
  name: 'SignUp',
  data() {
    return {
      firstName: '',
      lastName: '',
      email: '',
      cellphone: '',
      urlReferralCode: '',
      referralCode: '',
      showPopup: false,
      invalidCellphoneNumber: false,
      touched: {
        cellphone: false
      },
      acceptedTerms: false,
      errorMessage: '',
    }
  },
  computed: {
    isAcceptedTerms() {
      return this.acceptedTerms;
    },
    isFormValid() {
      return this.firstName && this.lastName && this.email && this.validateCellNumber() && this.acceptedTerms;
    }
  },
  methods: {
    viewTermsConditions() {
      window.open('/docs/Wowza Plus Reseller Terms & Conditions.pdf', '_blank');
    },
    viewCodeOfConduct() {
      window.open('/docs/Annexure B_ Reseller Code of Conduct.pdf', '_blank');
    },
    viewResellerCommission() {
      window.open('/docs/Annexure A_ Reseller Program Commission.pdf', '_blank');
    },
    logout() {
      this.$keycloak.logout();
    },
    formatCellNumber() {
      if (this.cellphone.startsWith("0") && this.cellphone.length === 10) {
        this.cellphone = this.cellphone.replace("0", "+27");
        return true;
      } else if (this.cellphone.startsWith("+27") && this.cellphone.length === 12) {
        return true;
      }
      return false;
    },
    validateCellNumber() {
      const pattern = /^(\+27|0)[0-9]{9}$/;
      return pattern.test(this.cellphone);
    },
    async createUser() {
      this.acceptedTerms = this.isAcceptedTerms;
      if (!this.acceptedTerms) {
        this.errorMessage = "Please accept T’s & C’s, Code of Conduct and Reseller Program."
        return;
      }

      const isValidNumber = this.formatCellNumber();
      const userData = {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        referredCode: this.referralCode,
        contacts: [
          {
            contactTypeEnum: 1,
            value: this.cellphone
          },
          {
            contactTypeEnum: 0,
            value: this.email
          }
        ],
        termsAndConditions: this.acceptedTerms
      };


      if (this.isFormValid && this.formatCellNumber() && this.acceptedTerms) {
        try {
          const response = await axiosInstance.post('/users', userData);
          if (response.status === 200) {
            this.$router.push('/dashboard');
          }
        } catch (error) {
          console.error(error);
          if (error.response && error.response.status === 400) {
            this.$router.push('/register');
            this.showPopup = true
          }
        }
      }

    }

  },
  async mounted() {
    this.urlReferralCode = this.$route.params.referralCode
    
    const userStore = useUserStore()

    const user = await userStore.getUser();

    if (user) {
      this.$router.push("/dashboard")
    }

    if (!this.urlReferralCode) {
      this.showPopup = true
      return;
    }

    const profile = await keycloak.loadUserProfile()

    this.firstName = profile.firstName
    this.lastName = profile.lastName
    this.email = profile.email
    this.referralCode = this.urlReferralCode
  }
}
</script>