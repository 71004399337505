import {defineStore} from 'pinia'
import {FulfillmentDto} from "@/areas/fulfillment/dtos/fulfillmentDto";
import {OperationResult} from "@/helpers/responses/models/operationResult";
import {FulfillmentProxy} from "@/areas/fulfillment/proxies/fulfillmentProxy";
import {ScanDto} from "@/areas/fulfillment/dtos/scanDto";

export const useFulfillmentStore = defineStore({
    id: "fulfillments",
    state: () => ({
        proxy: new FulfillmentProxy(),
    }),
    actions: {
        async GetOrCreateFulfillment(sessionGuid: string) : Promise<OperationResult<FulfillmentDto>> {
            return await this.proxy.GetOrCreate(sessionGuid)
        },

        async CancelFulfillment(fulfillmentGuid: string) : Promise<OperationResult<FulfillmentDto>> {
            return await this.proxy.Transition(fulfillmentGuid, "Cancelled")
        },

        async Scan(fulfillmentGuid: string, barcode: string) : Promise<OperationResult<ScanDto>> {
            return await this.proxy.Scan(fulfillmentGuid, barcode)
        },

        async DeleteScan(fulfillmentGuid: string, scanGuid: string) : Promise<OperationResult<ScanDto>> {
            return await this.proxy.DeleteScan(fulfillmentGuid, scanGuid)
        },

        async Fulfill(fulfillmentGuid: string) : Promise<OperationResult<FulfillmentDto>> {
            return await this.proxy.Transition(fulfillmentGuid, "Fulfilled")
        },
    }
})