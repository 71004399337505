

import {computed, defineComponent, ref} from "vue"
import {useSessionStore} from "@/areas/sessions/stores/sessionStore"
import AdminPortalSidebar from "@/components/Layouts/AdminPortalSidebar.vue";
import {SessionDto} from "@/areas/sessions/dtos/sessionDto";
import PrimarySubmit from "@/components/Atomic/AQuarks/PrimarySubmit.vue";
import ProcessOrder from "@/components/Atomic/BAtoms/ProcessOrder.vue";
import {useFulfillmentStore} from "@/areas/fulfillment/stores/fulfillmentStore";
import {FulfillmentDto} from "@/areas/fulfillment/dtos/fulfillmentDto";
import {DeliveryInfoDto} from "@/areas/sessions/dtos/deliveryInfoDto";
import {useReportingStore} from "@/areas/sessions/stores/reportingStore";
import SuccessFailModal from "@/components/Atomic/AQuarks/SuccessFailModal.vue";

export default defineComponent({
  components: {
    AdminPortalSidebar,
    PrimarySubmit,
    ProcessOrder,
    SuccessFailModal
  },
  async setup() {
    const reportingStore = useReportingStore();
    const sessionStore = useSessionStore()
    const fulfillmentStore = useFulfillmentStore();

    const sessions = ref<SessionDto[]>([]);
    const sessionId = ref<number | null>(null)

    const currentSort = ref('Order Number'); // initial default sort column
    const currentSortOrder = ref('asc');

    const activeSessionState = ref<string>("AwaitingDelivery")

    const activeFulfillment = ref<FulfillmentDto>();
    const deliveryDto = ref<DeliveryInfoDto>();

    const fulfillmentError = ref<string | undefined>(undefined);
    const deliveryInfoError = ref<string | undefined>(undefined);

    const showSuccessProcessedModal = ref(false);
    const showFailedDeleteScanModal = ref(false);

    const sortedSessions = computed(() => {
      let sorted = [...sessions.value];

      switch (currentSort.value) {
        case 'Order Number':
          sorted = sorted.sort((a, b) => a.id - b.id);
          break;

        case 'Name':
          sorted = sorted.sort((a, b) => `${a.user.firstName}`.localeCompare(`${b.user.firstName}`));
          break;

        case 'Surname':
          sorted = sorted.sort((a, b) => `${a.user.lastName}`.localeCompare(`${b.user.lastName}`));
          break;

        case 'Created':
          sorted = sorted.sort((a, b) => new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime());
          break;
      }

      if (currentSortOrder.value === 'desc') {
        sorted.reverse();
      }
      return sorted;
    });

    function showSuccessProcessed() {
      showSuccessProcessedModal.value = true;
      setTimeout(() => {
        showSuccessProcessedModal.value = false;
      }, 2000);
    }

    function showFailedDeleteScan() {
      showFailedDeleteScanModal.value = true;
      setTimeout(() => {
        showFailedDeleteScanModal.value = false;
      }, 4000);
    }


    async function handleSessionStateChange(event: Event) {
      const selectedValue = (event.target as HTMLSelectElement).value;

      return await fetchSessions(selectedValue);
    }

    async function fetchSessions(deliveryState: string) {
      activeSessionState.value = deliveryState

      const sessionResult = await sessionStore.getSessionsByState(deliveryState)

      if (!sessionResult.isSuccessful) {
        //Show modal here and go home

      }

      sessions.value = sessionResult.content!
    }

    async function handleProcessOrder(sessionGuid: string) {
      fulfillmentError.value = undefined
      deliveryInfoError.value = undefined

      const fulfillmentCreateResult = await fulfillmentStore.GetOrCreateFulfillment(sessionGuid)

      if (!fulfillmentCreateResult.isSuccessful) {
        
      } 

      activeFulfillment.value = fulfillmentCreateResult.content!
      
      const deliveryInfoResult = await reportingStore.getDeliveryInfo(sessionGuid)
      
      if (!deliveryInfoResult.isSuccessful){
        deliveryInfoError.value = deliveryInfoResult.error
      }
      
      deliveryDto.value = deliveryInfoResult.content!
    }
    
    async function handleCompleteOrder(sessionGuid: string) {
      const deliveryCompleteResult = await sessionStore.complete(sessionGuid)
      
      if (!deliveryCompleteResult.isSuccessful) {
        // state transition failure modal
      }
      await fetchSessions(activeSessionState.value)
    }


    async function handleCancelFulfillment(guid: string) {
      fulfillmentError.value = undefined

      const fulfillmentCancelResult = await fulfillmentStore.CancelFulfillment(guid)

      if (!fulfillmentCancelResult.isSuccessful) {
        fulfillmentError.value = fulfillmentCancelResult.error
        return
      }

      activeFulfillment.value = undefined
    }

    async function handleScan (fulfillment: string, barcode: string) {
      fulfillmentError.value = undefined

      const fulfillmentScanResult = await fulfillmentStore.Scan(fulfillment, barcode)

      if (!fulfillmentScanResult.isSuccessful) {
        fulfillmentError.value = fulfillmentScanResult.error
        return
      }

      activeFulfillment.value?.scans.push(fulfillmentScanResult.content!)
    }

    async function deleteScan (fulfillment: string, scanGuid: string) {
      fulfillmentError.value = undefined

      const fulfillmentScanResult = await fulfillmentStore.DeleteScan(fulfillment, scanGuid)

      if (!fulfillmentScanResult.isSuccessful) {
        //@stacey add an error modal here please
        fulfillmentError.value = fulfillmentScanResult.error;
        showFailedDeleteScan(); // Show the error modal
        return
      }
      
      activeFulfillment.value!.scans = activeFulfillment.value!.scans.filter(scan => scan.guid != scanGuid)
    } 

    async function processFulfillment (fulfillment: string) {
      fulfillmentError.value = undefined

      const fulfillmentScanResult = await fulfillmentStore.Fulfill(fulfillment)

      if (!fulfillmentScanResult.isSuccessful) {
        fulfillmentError.value = fulfillmentScanResult.error
        return
      }
      //@stacey we might want to show a success modal here
      showSuccessProcessed();

      activeFulfillment.value = undefined
      await fetchSessions(activeSessionState.value);
    }

    function generateOrderNumber(id: number): string {
      return "WOW" + id.toString().padStart(6, '0');
    }

    function formatDate(date: Date): string {
      const newDate = new Date(date)

      return `${newDate.getDate()} ${newDate.toLocaleString('en-Uk', {month: 'short'})} ${newDate.getFullYear()}`
    }

    function setSort(column: string) {
      if (currentSort.value === column) {
        currentSortOrder.value = currentSortOrder.value === 'asc' ? 'desc' : 'asc';
      } else {
        currentSort.value = column;
        currentSortOrder.value = 'asc';
      }
    }

    await fetchSessions(activeSessionState.value);

    return {
      sessions: sortedSessions,
      sessionId,
      currentSort,
      currentSortOrder,
      deliveryDto,
      activeFulfillment,
      fulfillmentError,
      showSuccessProcessedModal,
      showFailedDeleteScanModal,
      activeSessionState,
      setSort,
      generateOrderNumber,
      formatDate,
      handleSessionStateChange,
      handleProcessOrder,
      handleCancelFulfillment,
      handleCompleteOrder,
      handleScan,
      deleteScan,
      processFulfillment
    }

  }
})

