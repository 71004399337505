<template>
  <div class="flex justify-center">
    <div v-if="showNotification" class="fixed top-[70px] bg-[#DDFDE1] font-semibold py-3 rounded-sm z-40 w-full">
      <div class="flex justify-between items-center px-4">
        <div class="mx-auto pl-0 pr-2.5 sm:pl-24 sm:pr-0">
          Added to cart successfully 
        </div>
        <img class="h-5 cursor-pointer" src="../../../../public/img/cross.svg" alt="cross" @click="closeNotification">
      </div>
    </div>
  </div>
</template>


<script>
export default {
  props: {
    showNotification: Boolean,
  },
  methods: {
    closeNotification() {
      this.$emit("close");
    },
  },
};
</script>