<template>
  <TransitionRoot as="template" :show="sidebarOpen">
    <Dialog as="div" class="relative">
      <TransitionChild @close="sidebarOpen = false" as="template" enter="transition-opacity ease-linear duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="transition-opacity ease-linear duration-300" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-900 bg-opacity-30" />
      </TransitionChild>

      <div class="fixed inset-0 flex" @click="sidebarOpen = false">
        <div class="fixed inset-y-16 right-0">
          <TransitionChild as="template" enter="transition ease-in-out duration-300 transform"
                            enter-from="translate-x-full" enter-to="translate-x-0"
                            leave="transition ease-in-out duration-300 transform" leave-from="translate-x-0"
                            leave-to="translate-x-full">
            <DialogPanel class="relative mr-16 flex w-full max-w-xs flex-1">
              <div class="flex grow flex-col gap-y-5 overflow-y-auto bg-white overflow-x-hidden pb-4 rounded-b-lg shadow-lg">
                <div class="flex h-16 shrink-0 justify-center items-center">
                  <img class="h-32 w-auto" src="/img/logo.svg" alt="Soopa Connect" />
                </div>
                <nav class="flex flex-1 flex-col">
                  <ul role="list" class="flex flex-1 flex-col gap-y-7">
                    <li>
                      <ul role="list" class="space-y-1">
                        <li v-for="item in updatedNavigation" :key="item.name">
                          <a :href="item.href" :class="['group flex gap-x-3 font-montserrat p-2 text-sm leading-6', item.current ? 'text-black font-bold bg-[#38B6FF] bg-opacity-30 border-r-[2px] border-[#38B6FF]' : 'text-black font-semibold']" >
                            <div class="flex items-center">
                              <component :is="item.icon" :class="[item.current ? 'text-white' : 'text-indigo-200 group-hover:text-white', 'h-6 w-6 shrink-0']" aria-hidden="true"/>
                              <img class="h-5 mr-4" :src="'/img/' + item.icon">
                              <span>{{ item.name }}</span>
                            </div>  
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </nav>
                <PrimarySubmit @click="logout()" buttonText="Logout" class="text-white w-min px-12 bg-main-blue mx-auto" />
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>

  <div class="sticky top-0 z-10 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
    <a href="/dashboard">
      <img class="h-32" src="/img/logo.svg">
    </a>
    <h1 class="mx-auto font-montserrat text-xl font-bold">{{ pageHeader }}</h1>
    <button type="button" class="-m-2.5 p-2.5 text-gray-700" @click="sidebarOpen = !sidebarOpen">
      <img class="h-6" src="../../../public/img/menu.svg">
    </button>
  </div>
</template>


<script>
import { ref } from 'vue';
import PrimarySubmit from '../Atomic/AQuarks/PrimarySubmit.vue'
import {
  Dialog,
  DialogPanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue'

export default {
  components: {
    PrimarySubmit,
    Dialog, DialogPanel, Menu, MenuButton, MenuItem, MenuItems, TransitionChild, TransitionRoot,
  },

  props: {
    pageHeader: {
      type: String
    },
  },

  setup() {
    const sidebarOpen = ref(false);

    return { sidebarOpen };
  },

  data() {
    return {
      showContent: false,
      navigation: [
        {name: 'Customer Orders', href: '/admin/customer', current: false, icon: 'customer_orders.svg'},
        {name: 'Order Fulfillment', href: '/admin/fulfillment', current: false, icon: 'shipped_admin.png'},
        {name: 'Inventory', href: '/admin/inventory', current: false, icon: 'inventory.svg'},
        {name: 'Dashboard', href: '/dashboard', current: false, icon: 'dashboard_1.svg'},
      ],
    }
  },

  computed: {
    updatedNavigation() {
      return this.navigation.map(item => {
        const current = this.$route.path === item.href;
        return {
          ...item,
          current,
        };
      });
    },
  },

  methods: {
    logout() {
      this.$keycloak.logout();
    },
  },
}
</script>