import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import {createPinia} from 'pinia'
import keycloak from './keycloak';
import VueApexCharts from "vue3-apexcharts";
import RadialProgress from "vue3-radial-progress";
import '../public/css/tailwind.css'
import '@/assets/styles/index.css'


keycloak.init({onLoad: 'login-required', checkLoginIframe: false}).then((authenticated) => {
    if (authenticated) {
        const app = createApp(App);
        app.config.globalProperties.$keycloak = keycloak;
        app.use(router)
        app.use(VueApexCharts)
        app.use(RadialProgress);
        app.use(createPinia())
        app.mount('#app');
    } else {
        console.warn('Failed to authenticate');
    }
}).catch((err) => {
    console.error('Keycloak initialization failed', err);
});