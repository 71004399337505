export interface OperationResult<TContent> {
    content?: TContent,

    isSuccessful: boolean,

    error?: string
}

export class Operations {

    static ToSuccessResponse <TContent> (content: TContent): OperationResult<TContent> {
        return {
            content: content,
            isSuccessful: true
        }
    }

    static ToErrorResponse <TContent> (error: string): OperationResult<TContent> {
        return {
            isSuccessful: false,
            error: error
        }
    }
}
