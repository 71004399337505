import axiosInstance from "@/axiosConfig";
import {OperationResult} from "@/helpers/responses/models/operationResult";
import {FulfillmentDto} from "@/areas/fulfillment/dtos/fulfillmentDto";
import {Operations} from "@/helpers/responses/operations";
import {ProblemDetails} from "@/helpers/responses/models/problemDetails";
import {ScanDto} from "@/areas/fulfillment/dtos/scanDto";

export class FulfillmentProxy {

    public async GetOrCreate(sessionGuid: string): Promise<OperationResult<FulfillmentDto>> {
        const uri = `fulfillments/sessions/${sessionGuid}`

        try {
            const response = await axiosInstance.put<FulfillmentDto>(uri)

            return Operations.ToSuccessResponse(response.data)
        } catch (error : any) {

            if (error.response && error.response.status != 500) {
                const response = error.response

                const problemDetails: ProblemDetails = {
                    detail: response.data.detail || "Unknown error",
                    status: response.status,
                    type: response.data.type,
                }

                return Operations.ToErrorResponseFromProblemDetails(problemDetails)
            }

            return Operations.ToErrorResponse("RequestFailure")
        }
    }

    public async Scan(fulfillmentGuid: string, barcode: string): Promise<OperationResult<ScanDto>> {
        const uri = `fulfillments/${fulfillmentGuid}/scans`
    
        try {
            const response = await axiosInstance.put<ScanDto>(uri, {"barcode": barcode});
    
            return Operations.ToSuccessResponse(response.data)
        } catch (error : any) {

            if (error.response && error.response.status != 500) {
                const response = error.response

                const problemDetails: ProblemDetails = {
                    detail: response.data.detail || "Unknown error",
                    status: response.status,
                    type: response.data.type,
                }

                return Operations.ToErrorResponseFromProblemDetails(problemDetails)
            }

            return Operations.ToErrorResponse("RequestFailure")
        }
    }

    public async Transition(fulfillmentGuid: string, fulfillmentState: string): Promise<OperationResult<FulfillmentDto>> {
        const uri = `fulfillments/${fulfillmentGuid}/states/${fulfillmentState}`
    
        try {
            const response = await axiosInstance.put<FulfillmentDto>(uri);
    
            return Operations.ToSuccessResponse(response.data)
        } catch (error : any) {

            if (error.response && error.response.status != 500) {
                const response = error.response

                const problemDetails: ProblemDetails = {
                    detail: response.data.detail || "Unknown error",
                    status: response.status,
                    type: response.data.type,
                }

                return Operations.ToErrorResponseFromProblemDetails(problemDetails)
            }

            return Operations.ToErrorResponse("RequestFailure")
        }
    }
    
    public async DeleteScan(fulfillmentGuid: string, scanGuid: string): Promise<OperationResult<ScanDto>> {
        const uri = `fulfillments/${fulfillmentGuid}/scans/${scanGuid}`
    
        try {
            const response = await axiosInstance.delete<ScanDto>(uri);
    
            return Operations.ToSuccessResponse(response.data)
        } catch (error : any) {
            
            if (error.response && error.response.status != 500) {
                const response = error.response

                const problemDetails: ProblemDetails = {
                    detail: response.data.detail || "Unknown error",
                    status: response.status,
                    type: response.data.type,
                }

                return Operations.ToErrorResponseFromProblemDetails(problemDetails)
            }

            return Operations.ToErrorResponse("RequestFailure")
        }
    }
}

