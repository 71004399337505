<template>
  <div
      class="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-8 mt-10 font-montserrat font-semibold">
    <div v-if="pricing.tiers.length > 0" v-for="tier in pricing.tiers" :key="tier.id"
         :class="[tier.colour, ' drop-shadow-lg rounded-3xl p-8 xl:p-10', {'text-black': tier.colour === 'bg-white', 'text-white': tier.colour === 'bg-black'}]">
      <div class="flex items-center justify-between gap-x-4">
        <h2 :id="tier.id"
            :class="['text-lg lg:text-2xl font-semibold leading-8', {'text-blue-500': tier.colour === 'bg-white', 'text-white': tier.colour !== 'bg-white'}]">
          {{ tier.name }}</h2>
      </div>
      <p class="mt-6 flex items-baseline gap-x-1">
        <span class="text-4xl tracking-normal">{{ formattedPrice(tier.price) }}</span>
        <span class="text-sm leading-6"></span>
      </p>
      <ul role="list" class="mt-8 space-y-3 text-sm leading-6 xl:mt-10">
        <li v-for="feature in tier.features" :key="feature" class="flex gap-x-3">
          <img :src="getImage(tier)" class="h-6 w-5 flex-none"/>
          {{ feature }}
        </li>
      </ul>
      <button @click="addItemToCart(tier.id)" :disabled="tier.loading || tier.success || tier.error"
              :aria-describedby="tier.id"
              class="bg-gray-300 drop-shadow-md ml-auto text-black shadow-sm mt-6 w-[60%] sm:w-[60%] md:w-[60%] lg:w-[45%] block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2">
        <span v-if="tier.success" class="flex flex-row items-center justify-center animate-pulse duration-50"><img
            class="w-4 pr-2" src="../../../../public/img/added_to_cart.svg">Added To Cart</span>
        <span v-else-if="tier.loading"><img class="w-6 mx-auto animate-spin" src="../../../../public/img/progress.svg"></span>
        <span class="flex flex-row items-center justify-center" v-else><img class="w-4 pr-2"
                                                                            src="../../../../public/img/add_to_cart.svg">Add to Cart</span>
      </button>
    </div>
  </div>
  <div v-if="errorMessage" class="flex justify-center p-8 xl:p-10">
    <h2 class="text-lg mx-auto font-montserrat font-semibold">{{ errorMessage }}</h2>
  </div>
</template>

<script>
import {useSessionStore} from "@/areas/sessions/stores/sessionStore";
import axiosConfig from '../../../axiosConfig'
import bus from '../../../bus'
import CartNotification from './CartNotification.vue';

export default {
  async created() {
    try {
      let response = await axiosConfig.get('/catalogue?productCode=SIMB')
      let catalogueData = response.data

      if (catalogueData.length === 0) {
        this.errorMessage = "No items found in the catalogue."
        return;
      }

      //Sort the catalogue based on price
      catalogueData = catalogueData.sort((first, second) => first.amount - second.amount)

      this.pricing.tiers = catalogueData.map(item => {
        let descriptions = {};

        item.sku.descriptions.forEach(desc => {
          descriptions[desc.descKey] = desc.descValue;
        });

        return {
          name: descriptions["NAME"],
          id: item.guid,
          colour: this.getColourByPrice(item.amount),
          price: item.currency.symbol + item.amount,
          features: [
            descriptions["COUNT"],
            descriptions["EARN"],
            descriptions["AIRTIME"],
            descriptions["DATA"]
          ]
        }
      })
    } catch (error) {
      this.errorMessage = "An error occurred while fetching the catalogue."
      console.error(error);
    }
  },
  props: {
    catalogue: null,
  },
  data() {
    return {
      pricing: {
        tiers: []
      },
      errorMessage: "",
      showNotification: false,
    }

  },
  setup() {
    const sessionStore = useSessionStore();

    const init = async () => {
      let _initComplete = false;
      await validateSession();
      _initComplete = true
    };

    const validateSession = async () => {
      const session = await sessionStore.getOrCreateSession()
      if (session == null) {
        return;
      }
      return session;
    };

    return {sessionStore, validateSession}
  },
  components: {
    CartNotification
  },
  emits: ['package-added-to-cart'],
  methods: {
    async addItemToCart(tierId) {
      const tier = this.pricing.tiers.find(t => t.id === tierId);

      if (tier && !tier.loading) {
        tier.loading = true;

        const session = await this.sessionStore.getOrCreateSession();

        let sessionGuid = session.guid;

        try {
          const item = await this.sessionStore.addToCart(sessionGuid, tierId);

          if (item) {
            tier.success = true;

            this.$emit("package-added-to-cart");

            await this.validateSession();
            bus.emit('cart-updated');
          } else {
            tier.error = true;
          }

          tier.loading = false;

          setTimeout(() => {
            this.$nextTick(() => {
              tier.success = false;
              tier.error = false;
            });
          }, 2000);
        } catch (error) {
          console.error(error);

          tier.loading = false;
          tier.error = true;
        }
      }
    },
    getColourByPrice(price) {
      if (price <= 1250) {
        return 'bg-white';
      } else if (price <= 2250) {
        return 'bg-main-blue';
      } else if (price <= 2500) {
        return 'bg-pink';
      } else if (price <= 11250) {
        return 'bg-pink';
      } else {
        return 'bg-black';
      }
    },

    getImage(tier) {
      let imagePath;
      switch (tier.colour) {
        case 'bg-white':
          imagePath = '/img/check_black.svg';
          break;
        case 'bg-main-blue':
          imagePath = '/img/check_white.svg';
          break;
        case 'bg-pink':
          imagePath = '/img/check_black.svg';
          break;
        case 'bg-black':
          imagePath = '/img/check_pink.svg';
          break;
        default:
          imagePath = '';
          break;
      }
      return imagePath;
    },
    formattedPrice(price) {
      const priceParts = price.split(".");
      let integerPart = priceParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");

      // Remove the space after the currency symbol
      if (integerPart.startsWith('R ')) {
        integerPart = 'R' + integerPart.substring(2);
      }
      return integerPart + (priceParts[1] ? "." + priceParts[1] : "");
    },
  }
}
</script>