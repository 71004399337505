

import {computed, defineComponent, PropType, ref} from "vue";
import {DeliveryInfoDto} from "@/areas/sessions/dtos/deliveryInfoDto";

export default defineComponent({
  props: {
    deliveryDto: {
      type: Object as () => DeliveryInfoDto,
      required: true
    }
  },
  setup(props, ctx) {
    const isDefined = computed(() =>
        props.deliveryDto !== undefined &&
        props.deliveryDto.userDto !== undefined &&
        props.deliveryDto.lockerCode !== undefined &&
        props.deliveryDto.sessionGuid !== undefined
    )

    const customerName = computed(() => props.deliveryDto.userDto.firstName + " " + props.deliveryDto.userDto.lastName)
    
    const phoneNumber = computed( () => props.deliveryDto.userDto.contacts[0].value)
    
    function isValidAddress(): Boolean {
      const address = props.deliveryDto.userDto.addresses

      return address.street.length !== 0 &&
          address.suburb.length !== 0 &&
          address.province.length !== 0 &&
          address.city.length !== 0 &&
          address.postalCode.length !== 0
    }
    
    function hasCustomerInfo(): Boolean {
      const customer = props.deliveryDto.userDto
      
      return customer.firstName.length !== 0 &&
          customer.lastName.length !== 0 &&
          customer.email.length !== 0 &&
          customer.contacts[0].value.length !== 0
    }
    
    return {
      isDefined,
      customerName,
      phoneNumber,
      hasCustomerInfo,
      isValidAddress,
    };
  }
});
