<template>
<nav class="flex flex-1 flex-col">
  <ul role="list" class="flex flex-1 flex-col gap-y-7">
    <li>
      <ul role="list" class="space-y-1">
        <li v-for="item in updatedNavigation" :key="item.name">
          <a v-if="item.visible" :href="item.href" :class="['group flex gap-x-3 font-montserrat p-2 text-sm leading-6', item.current ? 'text-black font-bold bg-[#38B6FF] bg-opacity-30 border-r-[2px] border-[#38B6FF]' : 'text-black font-semibold']" >
            <div class="flex items-center">
              <component :is="item.icon" :class="[item.current ? 'text-white' : 'text-indigo-200 group-hover:text-white', 'h-6 w-6 shrink-0']" aria-hidden="true"/>
              <img class="h-5 mr-4" :src="'/img/' + item.icon">
              <div class="group relative cursor-pointer" v-if="ComingSoonTooltip(item)">
                <div class="absolute invisible bottom-5 left-12 group-hover:visible w-32 bg-[#F2F2F2] px-2 py-[5px] text-sm rounded-md">  
                  <p class="leading-2 text-black text-center">Coming Soon</p>
                  <svg class="absolute z-10 bottom-[-2.8px] left-[-5px] rotate-45" width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8 10L0 0L16 1.41326e-06L8 10Z" fill="#F2F2F2"/>
                  </svg>
                </div>
                <span :class="[['Earnings', 'SIM Assets', 'SIM Sales'].includes(item.name) ? 'text-darker-grey' : '']">{{ item.name }}</span>
              </div>
              <div v-else>
                <span :class="[['Earnings', 'SIM Assets', 'SIM Sales'].includes(item.name) ? 'text-darker-grey' : '']">{{ item.name }}</span>
              </div>
            </div>
          </a>
        </li>
      </ul>
    </li>
  </ul>
</nav>
</template>

<script>
import {onBeforeMount, ref} from 'vue';
import {useUserStore} from "@/areas/users/stores/userStore";
import router from "@/router";
import keycloak from '@/keycloak';


export default {
  props: {
    pageHeader: {
      type: String
    },
  },
  setup() {
    const sidebarOpen = ref(false);
    const user = ref([])

    const userStore = useUserStore();

    const init = async () => {
      let _initComplete = false;
      await validateUser();
      _initComplete = true;
    };

    const validateUser = async () => {
      const userResponse = await userStore.getUser()

      if (userResponse == null) {
        router.push("/register")
      }

      user.value = userResponse;
      return user;

    }

    onBeforeMount(async () => {
      await init();
    })

    return {sidebarOpen, user, userStore};
  },
  methods: {
    logout() {
      this.$keycloak.logout();
    },
    ComingSoonTooltip(item) {
    return ['Earnings', 'SIM Assets', 'SIM Sales'].includes(item.name);
  },
  },
  data() {
    return {
      showTooltip: false,
      referralCode: "",
      navigation: [
        {name: 'Dashboard', href: '/dashboard', current: false, icon: 'dashboard_1.svg'},
        {name: 'Orders', href: '/history', current: false, icon: 'orders_1.svg'},
        {name: 'Packages', href: '/packages', current: false, icon: 'packages_1.svg'},
        {name: 'Admin Portal', href: '/admin/customer', current: false, icon: 'orders_1.svg'},
        {name: 'Referrals', href: '/referrals', current: false, icon: 'referrals_2.svg'},
      ],
      userNavigation: [
        {name: 'Your profile', href: '#'},
        {name: 'Sign out', href: '#'},
      ],
    };
  },

  computed: {
  updatedNavigation() {
    const isAdmin = keycloak.hasRealmRole('admin');

    return this.navigation.map((item) => {
      const current = this.$route.path === item.href;
      const visible = isAdmin || item.name !== "Admin Portal";
      return {
        ...item,
        current,
        visible,
      }
    })
  },
},

};
</script>