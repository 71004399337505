<template>
  <DashLayout pageHeader="Business Dashboard"/>

  <div class="relative flex flex-row min-h-screen bg-blue-100">
    <main class="flex flex-col flex-grow md:ml-0 transition-all duration-150 ease-in font-montserrat lg:pl-72">
      <div class="main-content text-center pt-6">
        <h1 class="font-bold text-2xl drop-shadow-lg shadow-black">Order History</h1>
        <div v-if="userOrders && userOrders.length">
          <div v-for="order in userOrders" class="bg-white rounded-lg shadow-md mt-6 pb-2 w-[90%] mx-auto max-w-screen-xl">
              <div class="flex border-b border-black justify-between items-center font-medium px-6 pt-4 mb-2 text-sm space-x-6">
                <div class="flex flex-col items-start sm:flex-row mb-2 sm:items-center">
                    <div class="space-x-2 font-bold sm:border-r sm:pr-2.5 sm:mr-2.5">
                        <span>Order</span>
                        <span class="text-black">#WOW{{ formatId(order.session.id) }}</span>
                    </div>
                    <p class="sm:ml-auto flex flex-row font-bold text-sm my-2">
                      <img :src="getImage(order.session.sessionState)" class="w-5 mr-2">
                      {{ getSessionState(order.session.sessionState) }}
                    </p>
                </div>
                <div class="flex flex-col font-bold items-end mb-2">
                    <span>Total</span>
                    <span class="text-black font-bold font-montserrat text-lg">R {{ computeOrderTotal(order) }}.00</span>
                </div>
            </div>

            <div class="flex justify-between">
                <div class="flex-1">
                  <div v-for="item in order.sessionItems" :key="item.guid" class="flex justify-between">
                      <div class="w-full mx-auto py-2 px-6">
                          <div class="flex flex-row justify-between">
                              <span class="text-black font-bold text-lg sm:text-xl">{{ item.description.descValue }}</span>
                              <span class="text-black font-bold text-sm sm:text-md">Qty: {{ item.item.quantity }}</span>
                          </div>
                          <div class="flex justify-between text-black font-semibold mt-1">
                              <span>Subtotal</span>
                              <span>{{ item.catalogueEntry.currency.symbol }} {{ item.catalogueEntry.amount * item.item.quantity }}.00</span>
                          </div>
                      </div>
                  </div>                  
                </div>
            </div> <!-- End of new flex container -->
            <div v-show="order.session.sessionState == 'AwaitingPayment'" class="border-t"></div>
            <div class="flex flex-row sm:justify-between justify-center">
              <div class="hidden sm:block"></div>
              <div class="flex flex-row sm:justify-end w-full px-4 sm:px-0 sm:w-1/3">
                <button @click="cancelOrder(order.session.guid)" class="bg-white w-full sm:w-1/3 min-w-max border border-black text-black font-bold text-xs px-2 mr-4 mt-4 mb-2 py-3 font-montserrat rounded-lg self-end" v-show="order.session.sessionState == 'AwaitingPayment'">
                  CANCEL ORDER
                </button>
                <button @click="createPaymentIntent(order.session.guid)" class="bg-green w-full sm:w-1/3 min-w-max text-white font-bold text-xs px-4 sm:mr-4 mt-4 mb-2 py-3 font-montserrat rounded-lg self-end" v-show="order.session.sessionState == 'AwaitingPayment'">
                  PAY NOW
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-col justify-center items-center" v-else>
          <img src="img/empty_order_history.svg" alt="No sessions available" class="w-[80%] sm:w-[45%] md:w-[40%] lg:w-[25%] mx-auto mt-6">
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import {defineComponent, onMounted, onUnmounted, onBeforeMount, ref, watch, computed } from 'vue';
import { useSessionStore} from '@/areas/sessions/stores/sessionStore';
import { useCatalogueStore} from "@/areas/catalogues/stores/catalogueStore";
import DashLayout from '../components/Layouts/DashLayout.vue'

export default {
  components: {
    DashLayout
  },

  setup() {
    const sidebarOpen = ref(false);

    const sessionStore = useSessionStore();
    const catalogueStore = useCatalogueStore();
    const userOrders = ref([]);

    const userSessions = ref([]);
    const catalogueEntries = ref([]);
    const isLoadingCatalogueEntries = ref(true);  // new loading state
    const catalogueError = ref(null);  // Error handling

    const init = async () => {
      let _initComplete = false;
      await fetchOrderHistory();
      _initComplete = true;
    };

    const fetchOrderHistory = async () => {
      const orders = await sessionStore.fetchOrderHistory();

      if(orders == null) {
        return;
      }

      userOrders.value = orders;
      return orders;
    }

    onBeforeMount(async () => {
      await init();
    })

    return {
      userOrders,
      sidebarOpen,
      sessionStore,
      userSessions,
      catalogueStore,
      catalogueEntries,
      isLoadingCatalogueEntries, // expose the loading state
      catalogueError // expose the error state
    }
  },

  methods: {
    computeOrderTotal(order) {
        return order.sessionItems.reduce((total, item) => total + item.catalogueEntry.amount * item.item.quantity, 0);
    },
    getSessionState(sessionState) {
      if(sessionState == "AwaitingPayment") {
        return "Awaiting Payment"
      } else {
        return sessionState;
      }
    },

    formatId(id) {
      return id.toString().padStart(6, '0');
    },

    async createPaymentIntent(sessionGuid) {
      const paymentIntentResponse = await this.sessionStore.createPaymentIntent(sessionGuid);
      if(paymentIntentResponse) {          
        window.location.href = paymentIntentResponse.redirectUrl;
      }
    },

    async cancelOrder(sessionGuid) {
      const orderCancelResponse = await this.sessionStore.cancelOrder(sessionGuid);
      if(orderCancelResponse) {
        window.location.reload()
      }
    },

    getImage(sessionState) {
    const images = {
      'AwaitingDelivery': '/img/awaitingDelivery.svg',
      'AwaitingPayment': '/img/payment_processing.svg',
      'Cancelled': '/img/cancelled.svg',
      'Delivery': '/img/Delivery_Order_History.svg',
      'Completed': '/img/delivered.svg',
    };

    return images[sessionState] || 'nothing.jpg';
  }
    
},

}
</script>