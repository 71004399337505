<template>
  <div>
    <!-- Sidebar Tablet & Mobile -->
    <TransitionRoot as="template" :show="sidebarOpen">
      <Dialog as="div" class="relative lg:hidden">
        <TransitionChild @close="sidebarOpen = false" as="template" enter="transition-opacity ease-linear duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="transition-opacity ease-linear duration-300" leave-from="opacity-100" leave-to="opacity-0">
          <div class="fixed inset-0 bg-gray-900 bg-opacity-30" />
        </TransitionChild>

        <div class="fixed inset-0 flex lg:hidden" @click="sidebarOpen = false">
          <div class="fixed inset-y-16 right-0">
            <TransitionChild as="template" enter="transition ease-in-out duration-300 transform"
                             enter-from="translate-x-full" enter-to="translate-x-0"
                             leave="transition ease-in-out duration-300 transform" leave-from="translate-x-0"
                             leave-to="translate-x-full">
              <DialogPanel class="relative mr-16 flex w-full max-w-xs flex-1">
                <div class="flex grow flex-col gap-y-5 overflow-y-auto bg-white overflow-x-hidden pb-4 rounded-b-lg shadow-lg">
                  <div class="flex h-16 shrink-0 justify-center items-center">
                      <img class="h-40 w-auto" src="/img/logo.svg" alt="Soopa Connect" />
                  </div>
                  <MenuLayout />
                  <PrimarySubmit @click="logout()" buttonText="Logout" class="text-white w-min px-12 bg-main-blue mx-auto" />
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>

    <!-- Static sidebar for desktop -->
    <div class="hidden lg:fixed lg:inset-y-0 lg:z-10 lg:flex lg:w-72 lg:flex-col">
      <div class="flex grow flex-col gap-y-5 overflow-y-auto drop-shadow-lg bg-white overflow-x-hidden pb-4">
        <div class="flex h-16 shrink-0 items-center justify-center">
          <img class="h-32 w-auto" src="../../../public/img/logo.svg" alt="Soopa Connect"/>
        </div>
        <MenuLayout />
      </div>
    </div>

    <div class="lg:pl-72">
      <div class="sticky top-0 z-10 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
        <img class="h-32 lg:hidden" src="/img/logo.svg">
        <h1 class="mx-auto font-montserrat text-xl font-bold">{{ pageHeader }}</h1>
        <button type="button" class="-m-2.5 p-2.5 text-gray-700 lg:hidden" @click="sidebarOpen = !sidebarOpen">
          <img class="h-6" src="/img/menu.svg">
        </button>
        <PrimarySubmit @click="logout()" buttonText="Logout" class="hidden w-min px-6 max-h-11 lg:block text-white bg-main-blue" />
      </div>
    </div>
  </div>
</template>

<script>
import {onBeforeMount, ref} from 'vue';
import {
  Dialog,
  DialogPanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue'

import PrimarySubmit from '../Atomic/AQuarks/PrimarySubmit.vue'
import MenuLayout from './MenuLayout.vue';
import {useUserStore} from "@/areas/users/stores/userStore";
import router from "@/router";

export default {
  components: {
    Dialog, DialogPanel, Menu, MenuButton, MenuItem, MenuItems, TransitionChild, TransitionRoot, PrimarySubmit, MenuLayout
  },
  props: {
    pageHeader: {
      type: String
    },
  },
  setup() {
    const sidebarOpen = ref(false);
    const user = ref([])

    const userStore = useUserStore();

    const init = async () => {
      let _initComplete = false;
      await validateUser();
      _initComplete = true;
    };

    const validateUser = async () => {
      const userResponse = await userStore.getUser()

      if (userResponse == null) {
        router.push("/register")
      }

      user.value = userResponse;
      return user;

    }

    onBeforeMount(async () => {
      await init();
    })

    return {sidebarOpen, user, userStore};
  },
  methods: {
    logout() {
      this.$keycloak.logout();
    },
    ComingSoonTooltip(item) {
    return ['Earnings', 'SIM Assets', 'SIM Sales'].includes(item.name);
    },
  },
  data() {
    return {
      showTooltip: false,
      referralCode: "",
      navigation: [
        {name: 'Dashboard', href: '/dashboard', current: false, icon: 'dashboard_1.svg'},
        {name: 'Orders', href: '/history', current: false, icon: 'orders_1.svg'},
        {name: 'Packages', href: '/packages', current: false, icon: 'packages_1.svg'},
        {name: 'Referrals', href: '/referrals', current: false, icon: 'referrals_2.svg'},
        {name: 'Earnings', href: '#', current: false, icon: 'earnings_2.svg'},
        {name: 'SIM Assets', href: '#', current: false, icon: 'sim_assets_2.svg'},
        {name: 'SIM Sales', href: '#', current: false, icon: 'sim_sales_2.svg'},
        {name: 'Admin Portal', href: '/admin/deliveries', current: false, icon: 'orders_1.svg'}
      ],
      userNavigation: [
        {name: 'Your profile', href: '#'},
        {name: 'Sign out', href: '#'},
      ],
    };
  },
  computed: {
  updatedNavigation() {
    return this.navigation.map(item => {
      const current = this.$route.path === item.href;
      return {
        ...item,
        current,
      };
    });
  },
},

};
</script>