import axiosInstance from "@/axiosConfig";
import {SessionDto} from "@/areas/sessions/dtos/sessionDto";
import {Operations} from "@/helpers/responses/operations";
import {ProblemDetails} from "@/helpers/responses/models/problemDetails";

export class ReportingProxy {

    public async ContactInformation(sessionGuid: string) {
        const uri = `/reports/deliveries?sessionGuid=${sessionGuid}`

        try {
            const response = await axiosInstance.get(uri)

            return Operations.ToSuccessResponse(response.data);
        } catch (error: any) {
            if (error.reponse && error.response.status != 500){
                const response = error.response
                
                const problemDetails: ProblemDetails = {
                    detail: response.data.details || "Unknown error",
                    status: response.status,
                    type: response.data.type,
                }
                
                return Operations.ToErrorResponseFromProblemDetails(problemDetails)
            }
            
            return Operations.ToErrorResponse("RequestFailure")
        }

    }

    public async ItemInformation(sessionGuid: string) {
        const uri = `/reports/sessions/${sessionGuid}/items`
        console.log("ItemInfo:" + uri)

        try {
            const response = await axiosInstance.get(uri)
            console.log(response.data)

            return response.data;
        } catch (error) {
            console.error(error);
            return null;
        }

    }
}