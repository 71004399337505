import {OperationResult} from "@/helpers/responses/models/operationResult";
import {ProblemDetails} from "@/helpers/responses/models/problemDetails";

export class Operations {

    static ToSuccessResponse<TContent>(content: TContent): OperationResult<TContent> {
        return {
            content: content,
            isSuccessful: true
        }
    }

    static ToErrorResponse<TContent>(error: string): OperationResult<TContent> {
        return {
            isSuccessful: false,
            error: error
        }
    }

    static ToErrorResponseFromProblemDetails<TContent>(error: ProblemDetails): OperationResult<TContent> {
        return {
            isSuccessful: false,
            error: error.type
        }
    }
}