const cycleStartDate = 24
const cycleEndDate = 25

export function calculateCycleDates(date: Date): [Date, Date] {
    let year = date.getFullYear();
    let month = date.getMonth();
    let day = date.getDate();

    // Find the closest 24th before the current date
    let startDate: Date;

    if (day > cycleStartDate) {
        // If it's past the 24th of the month, the closest 24th is this month
        startDate = new Date(year, month, date.getDate());
    } else {
        // If it's before the 24th, the closest 24th is last month
        startDate = new Date(year, month - 1, cycleStartDate);
    }

    // Find the closest 25th after the current date
    let endDate: Date;

    if (day <= cycleEndDate) {
        // If it's before the 25th of the month, the closest 25th is this month
        endDate = new Date(year, month, cycleEndDate);
    } else {
        endDate = new Date(year, month + 1, cycleEndDate);
    }

    return [
        startDate,
        endDate];
}