import axiosInstance from "@/axiosConfig";

export class UserProxy {

    public async GetUserAddress() {
        const uri = '/users/address'

        try {
            const response = await axiosInstance.get(uri)
            return response.data;
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    public async GetUserContact() {
        const uri = '/users/contact'

        try {
            const response = await axiosInstance.get(uri)
            return response.data;
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    public async GetUser() {
        const uri = '/users'

        return await axiosInstance.get(uri)
            .then(function (response) {
                return response.data

            })
            .catch(function (error) {
                if (error.response) {
                    if (error.response.status == 404) {
                        return null;
                    }
                } else {
                    console.error(error);
                    return null;
                }
            });
    }

}