import {defineStore} from 'pinia'
import {ReportingProxy} from '@/areas/sessions/proxies/reportingProxy';
import {SessionDto} from '@/areas/sessions/dtos/sessionDto';
import {DeliveryInfoDto} from '@/areas/sessions/dtos/deliveryInfoDto';
import {CatalogueEntryDto} from "@/areas/catalogues/dtos/CatalogueEntryDto";
import {OperationResult} from "@/helpers/responses/models/operationResult";

export const useReportingStore = defineStore({
    id: "reporting",
    state: () => ({
        updated_at: new Date(),
        sessions: null as Array<SessionDto> | null,
        contacts: {} as Record<string, DeliveryInfoDto>,
        proxy: new ReportingProxy()
    }),
    getters:{
        getContactInfo: (state) => state.contacts,
    },

    actions: {
        async getDeliveryInfo(sessionGuid: string): Promise<OperationResult<DeliveryInfoDto>> {
            return await this.proxy.ContactInformation(sessionGuid)
        },
        
        async getContactInformation(sessionDto: SessionDto) {
            if(this.contacts[sessionDto.guid] !== undefined){
                return this.contacts[sessionDto.guid] as DeliveryInfoDto;
            }

            const contactDetails = await this.proxy.ContactInformation(sessionDto.guid);
            
            if(!contactDetails) {
                return null;
            }
        },
        async getItemInformation(sessionDto: SessionDto) {
            const itemDetails = await this.proxy.ItemInformation(sessionDto.guid);

            if(!itemDetails) {
                return null;
            }
            return itemDetails as Array<CatalogueEntryDto>;
        },
    }
    
})
