import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import PackagePage from '../views/PackagePage.vue'
import OrderConfirmation from '../views/OrderConfirmation.vue'
import CheckoutPage from '../views/CheckoutPage.vue'
import DashboardView from '../views/DashboardView.vue'
import RegisterView from '../views/RegisterView.vue'
import OrderHistory from '../views/OrderHistory.vue'
import PaymentView from '../views/PaymentView.vue'
import AdminOrders from "../views/AdminOrders.vue"
import OrderFulfillment from "../views/OrderFulfillment.vue"
import keycloak from '@/keycloak'
import ReferralView from '../views/ReferralView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/dashboard'
  },
  {
    path: '/packages',
    name: 'Choose Package',    
    component: PackagePage,
  },
  {
    path: '/confirmation',
    name: 'Order Completed',    
    component: OrderConfirmation,
  },
  {
    path: '/checkout',
    name: 'Checkout',
    component: CheckoutPage,
  },
  {
    path: '/history',
    name: 'Order History',
    component: OrderHistory,
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: DashboardView,
  },
  {
    path: '/referrals',
    name: 'Referrals',
    component: ReferralView,
  },
  {
    path: '/register/:referralCode?',
    name: 'Register',
    component: RegisterView,
  },
  {
    path: '/payment/:status/:sessionGuid',
    name: 'Payment',
    component: PaymentView
  },
  {
    path: '/admin/customer',
    name: 'Admin Orders',
    component: AdminOrders,
    beforeEnter: (to, from, next) => {
      if(keycloak.hasRealmRole('admin')) {
        next();
      } else {
        next('/');
      }
    }
  },
  {
    path: '/admin/fulfillment',
    name: 'Order Fulfillment',
    component: OrderFulfillment,
    beforeEnter: (to, from, next) => {
      if(keycloak.hasRealmRole('admin')) {
        next();
      } else {
        next('/');
      }
    }
  },

]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
