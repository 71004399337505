import {defineStore} from 'pinia'
import {SessionProxy} from '@/areas/sessions/proxies/sessionProxy'
import {SessionDto} from '@/areas/sessions/dtos/sessionDto';
import {AddressDto} from '@/areas/users/dtos/addressDto';
import {ContactDto} from '@/areas/users/dtos/contactDto';
import {OperationResult} from "@/helpers/responses/models/operationResult";

export const useSessionStore = defineStore({
    id: "session",
    state: () => ({
        updated_at: new Date(),
        session: null as SessionDto | null,
        items: [],
        proxy: new SessionProxy,
    }),
    actions: {

        async getSessionsByState(sessionStateEnum: string): Promise<OperationResult<SessionDto[]>> {
            return await this.proxy.SessionsByState(sessionStateEnum)
        },
        
        async complete(sessionGuid: string): Promise<OperationResult<SessionDto>>{
            return await this.proxy.Complete(sessionGuid)
        },

        async getOrCreateSession() {
            const session = await this.proxy.GetOrCreate();
        
            if(!session){
                return null;
            }
            
            this.session = session;
            return session as SessionDto;
        },

        async fetchOrderHistory() {
            const orders = await this.proxy.fetchOrderHistoryList();

            if(!orders) {
                return null;
            }

            return orders;
        },

        async listSessions() {
            const sessions = await this.proxy.getOpenSessions();

            if(!sessions){
                return null;
            }

            return sessions;
        },

        async getSessionByGuid(sessionGuid: string) {
            const session = await this.proxy.getSessionByGuid(sessionGuid);

            if(!session){
                return null;
            }

            return session;
        },
        
        async addToCart(sessionGuid: string, itemGuid: string) {
            const item = await this.proxy.AddToCart(sessionGuid, itemGuid);
            if (!item) {
                return null;
            }
            
            return item;
        },        
        
        async listCartItems() {
            const session = await this.proxy.GetOrCreate();
            if (!session) {
                return null;
            }
            this.items = session.items;
            return this.items;
        },

        async deleteCartItem(sessionGuid: string, itemGuid: string) {
            const response = await this.proxy.DeleteCartItem(sessionGuid, itemGuid);
            if(!response) {
                return null;
            }
            return response;

        },
        
        async updateCart(itemGuid: string, sessionGuid: string, quantity: Number ) {
            const item = await this.proxy.UpdateCart(itemGuid, sessionGuid, quantity);
            if (!item) {
                return null;
            }
            return item;
        },

        async createDelivery(address: AddressDto, emailContact: ContactDto, mobileContact: ContactDto, sessionGuid: string, externalId: string) {
            const delivery = await this.proxy.CreateDelivery(address, emailContact, mobileContact, sessionGuid, externalId);
            if(!delivery) {
                return null;
            }
            return delivery;
        },

        async createPaymentIntent(sessionGuid: string) {
            const paymentIntent = await this.proxy.CreatePaymentIntent(sessionGuid);
            if(!paymentIntent) {
                return null;
            }
            return paymentIntent;
        },

        async cancelOrder(sessionGuid: string) {
            const cancelOrderResponse = await this.proxy.CancelOrder(sessionGuid);
            if(!cancelOrderResponse) {
                return null;
            }
            return cancelOrderResponse;
        }

    }
})