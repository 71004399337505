<template>
  <nav aria-label="Progress">
    <!-- Mobile steps -->
    <ol role="list" class="flex w-full items-center justify-center space-x-0 sm:space-x-12 md:hidden -ml-1.5">
      <li v-for="step in steps" :key="step.name">
        <a v-if="step.status === 'complete'" :href="step.href" class="relative space-y-2 space-x-4 whitespace-nowrap flex flex-col items-center justify-center">
          <span class="relative block h-4 w-4 rounded-full border bg-green ml-4" aria-hidden="true" />
          <span class="text-xs w-15 text-center font-montserrat font-semibold">{{ step.name }}</span>
        </a>
        <a v-else-if="step.status === 'current'" :href="step.href" class="relative space-y-3.5 space-x-4 whitespace-nowrap flex flex-col" aria-current="step">
          <div class="flex justify-center items-center ml-3">
            <span class="absolute flex h-5 w-5 p-px" aria-hidden="true">
              <span class="h-full w-full rounded-full bg-indigo-200" />
            </span>
            <span class="relative block h-3 w-3 rounded-full border bg-blue-500" aria-hidden="true" />
          </div>
          <span class="text-xs w-15 text-center font-montserrat font-semibold">{{ step.name }}</span>
        </a>
        <a v-else :href="step.href" class="relative space-y-2 space-x-4 whitespace-nowrap flex flex-col items-center justify-center">
          <span class="relative block h-4 w-4 rounded-full border bg-gray-400 ml-4" aria-hidden="true" />
          <span class="text-xs w-15 text-center font-montserrat font-semibold">{{ step.name }}</span>        </a>
      </li>
    </ol>
    <!-- Desktop steps -->
    <ol role="list" class="space-y-4 hidden md:flex md:space-x-8 md:space-y-0">
      <li v-for="step in steps" :key="step.name" class="md:flex-1">
        <a v-if="step.status === 'complete'" :href="step.href" class="group flex flex-col border-l-4 border-green py-2 pl-4 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4">
          <span class="text-sm font-montserrat font-medium text-blue-500">{{ step.id }}</span>
          <span class="text-sm font-montserrat font-semibold">{{ step.name }}</span>
        </a>
        <a v-else-if="step.status === 'current'" :href="step.href" class="flex flex-col border-l-4 border-blue-500 py-2 pl-4 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4" aria-current="step">
          <span class="text-sm font-montserrat font-medium text-blue-500">{{ step.id }}</span>
          <span class="text-sm font-montserrat font-semibold">{{ step.name }}</span>
        </a>
        <a v-else :href="step.href" class="group flex flex-col border-l-4 border-gray-300 py-2 pl-4 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4">
          <span class="text-sm font-montserrat font-medium text-gray-500">{{ step.id }}</span>
          <span class="text-sm font-montserrat font-semibold">{{ step.name }}</span>
        </a>
      </li>
    </ol>
  </nav>
</template>

<script setup>
import { ref, watchEffect } from 'vue'

// props
const props = defineProps({
  currentStep: {
    type: Number,
    required: true
  }
})

// steps data
const steps = ref([
  { id: 'Step 1', name: 'Sign Up', href: '#', status: 'upcoming' },
  { id: 'Step 2', name: 'Choose Package', href: '/packages', status: 'upcoming' },
  { id: 'Step 3', name: 'Checkout', href: '/checkout', status: 'upcoming' },
  { id: 'Step 4', name: 'Payment', href: '/payment', status: 'upcoming' },
])

// update status based on currentStep prop
watchEffect(() => {
  steps.value = steps.value.map((step, index) => {
    if ((index + 1) < props.currentStep) {
      return { ...step, status: 'complete' }
    } else if ((index + 1) === props.currentStep) {
      return { ...step, status: 'current' }
    } else {
      return { ...step, status: 'upcoming' }
    }
  })
})
</script>