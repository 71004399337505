<template>
  <Suspense>
    <div id="app">
      <router-view/>
    </div>
  </Suspense>
</template>

<script>
export default {
  name: 'App',
};
</script>

<style>
@import './assets/styles/index.css';
</style>
