

import {defineComponent} from "vue";
import HeaderCard from "@/components/Atomic/BAtoms/HeaderCard.vue";

export default defineComponent({
  components: {
    HeaderCard
  },
  props: {
    title: {
      required: true,
      type: String
    },
    indirectValue: {
      required: true,
      type: String
    },
    directValue: {
      required: true,
      type: String
    },
    imagePath: {
      required: true,
      type: String
    }
  }

})

