<template>
  <MainLayout navbarText="Checkout">
    <div class="mt-6 sm:mt-12 w-3/4 mx-auto">
      <ProgressStep :currentStep=3 />
    </div>

    <main class="relative mt-4 mx-auto flex flex-col max-w-7xl gap-x-16 lg:px-8 font-montserrat bg-blue-100">

    <section v-show="cartSummaryConfirmed" aria-labelledby="payment-and-shipping-heading" class="lg:col-start-1 lg:row-start-1 lg:mx-auto lg:w-full lg:max-w-lg lg:pb-24 lg:pt-0">
      <h2 id="payment-and-shipping-heading" class="sr-only">Payment and shipping details</h2>

      <form>
        <div class="mx-auto max-w-2xl px-4 lg:max-w-none lg:px-0">
          <div>
            <h3 id="contact-info-heading" class="text-xl sm:text-2xl font-medium text-black">Contact Information</h3>

            <div class="mt-6">
                <label for="email-method" class="block text-md font-medium text-black">Email address</label>
                <select v-model="selectedEmail" @change="onChange('email')" class="block w-full rounded-md px-2 border border-[#CBD6E2] sm:text-sm py-2">
                    <option v-for="email in userEmailContacts" :key="email" :value="email">{{ email.value }}</option>
                    <option value="other">Other...</option>
                </select>
                <input v-if="showEmailInput" v-model="customEmail" placeholder="Enter your email address" class="block w-full rounded-md px-2 border border-[#CBD6E2] sm:text-sm py-2 mt-2">
                <p v-if="emailError" class="text-red mt-1 text-sm">{{ emailError }}</p>

                <label for="phone-method" class="block text-md font-medium text-black mt-5">Cellphone number</label>
                <select v-model="selectedPhone" @change="onChange('phone')" class="block w-full rounded-md px-2 border border-[#CBD6E2] sm:text-sm py-2">
                    <option v-for="phone in userMobileContacts" :key="phone" :value="phone">{{ phone.value }}</option>
                    <option value="other">Other...</option>
                </select>
                <input
                  v-if="showPhoneInput"
                  v-model="customPhone"
                  placeholder="Enter your cellphone number"
                  class="block w-full rounded-md px-2 border border-[#CBD6E2] sm:text-sm py-2 mt-2"
                  @blur="formatAndValidatePhone"
                >
                <p v-if="phoneError" class="text-red mt-1 text-sm">{{ phoneError }}</p>
            </div>
          </div>

          <!-- Show user's last used address -->
          <div v-if="userHasExistingAddress && addressState == 'LastAddress'" class="mt-10">
            <div class="flex flex-row justify-between">
              <h3 class="text-xl sm:text-2xl font-medium text-black">Delivery Address</h3>
              <h3 @click="triggerAddressChange()" class="text-xl font-semibold cursor-pointer text-[#8B85D2]">Change</h3>
            </div>
            <div class="mt-6 flex flex-col items-start font-medium">
              <p>{{ lastUsedAddress.street }}</p>
              <p>{{ lastUsedAddress.suburb }}, {{ lastUsedAddress.city }}, {{ lastUsedAddress.postalCode }}</p>
            </div>
          </div>

          <!-- Show user's existing addresses -->
          <div v-if="userHasExistingAddress && addressState == 'AddressHistory'" class="mt-10">
            <div class="flex flex-row justify-between items-center">
              <h3 class="text-xl sm:text-2xl font-medium text-black">Delivery Address</h3>
              <PrimarySubmit @click="addNewAddress()" class="text-white bg-main-blue w-fit px-4" buttonText="Add Address"/>
            </div>
            <ul class="bg-white rounded-md py-4 space-y-4 mt-4">
              <li v-for="(address, index) in userAddresses" :key="address.id">
                <div class="flex flex-row px-4 justify-between" :class="{'border-b': index !== userAddresses.length - 1}">
                  <div class="flex flex-col">
                    <div class="flex flex-row items-center">
                      <input type="radio" :id="address.id" :value="address" v-model="altAddress" name="locker" class="form-radio h-4 w-4 text-blue-600">
                      <label :for="address.id" class="ml-2 text-sm font-medium">{{ address.address.street }}</label>
                    </div>
                    <p class="ml-6 text-xs pt-2" :class="{'pb-4': index !== userAddresses.length - 1}">{{ address.address.suburb }}, {{ address.address.city }}, {{ address.address.postalCode }}</p>
                  </div>
                </div>
              </li>
            </ul>
            <p v-if="altAddressError" class="text-red mt-1 text-sm">{{ altAddressError }}</p>
            <div class="flex flex-row justify-between">
              <div></div>
              <PrimarySubmit @click="newAddressSelected()" buttonText="OK" class="text-white bg-green w-[35%] mt-4"/>
            </div>
          </div>

          <!-- Show user's new selected alt address -->
          <div v-if="altAddress && addressState == 'AltAddressSelected'" class="mt-10">
            <div class="flex flex-row justify-between">
              <h3 class="text-xl sm:text-2xl font-medium text-black">Delivery Address</h3>
              <h3 @click="triggerAddressChange()" class="text-xl font-semibold cursor-pointer text-[#8B85D2]">Change</h3>
            </div>
            <div class="mt-6 flex flex-col items-start font-medium">
              <p>{{ altAddress.address.street }}</p>
              <p>{{ altAddress.address.suburb }}, {{ altAddress.address.city }}, {{ altAddress.address.postalCode }}</p>
            </div>
          </div>

          <!-- Show user's new address -->
          <div v-if="addressState == 'NewAddressAdded'" class="mt-10">
            <div class="flex flex-row justify-between">
              <h3 class="text-xl sm:text-2xl font-medium text-black">Delivery Address</h3>
              <h3 @click="triggerAddressChange()" class="text-xl font-semibold cursor-pointer text-[#8B85D2]">Change</h3>
            </div>
            <div class="mt-6 flex flex-col items-start font-medium">
              <p>{{ userNewAddress[userNewAddress.length - 1].address.street }}</p>
              <p>{{ userNewAddress[userNewAddress.length - 1].address.suburb }}, {{ userNewAddress[userNewAddress.length - 1].address.city }}, {{ userNewAddress[userNewAddress.length - 1].address.postalCode }}</p>
            </div>
          </div>

          <!-- Show if no existing address -->
          <div v-show="(!userHasExistingAddress && !changeAddress) || addressState == 'AddNewAddress'">
            <div class="mt-8">
              <h3 class="text-xl sm:text-2xl font-medium text-black">Delivery Address</h3>

              <div class="mt-6 grid grid-cols-3 gap-x-4 gap-y-6 sm:grid-cols-4">
                <div class="col-span-3 sm:col-span-4">
                  <label class="block text-md font-medium text-black">Street Address</label>
                  <div class="mt-1">
                    <input type="text" id="autocomplete" v-model="streetAddress" :class="{'border-red-500': !streetAddress}" class="block w-full px-2 rounded-md border border-[#CBD6E2] sm:text-sm py-2">
                  </div>
                </div>

                <div class="col-span-3 sm:col-span-4">
                  <label class="block text-md font-medium text-black">Suburb</label>
                  <div class="mt-1">
                    <input type="text" id="area" v-model="suburb" :class="{'border-red-500': !suburb}" class="block w-full px-2 rounded-md border border-[#CBD6E2] sm:text-sm py-2">
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div class="mt-6 grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-3">
                <div>
                  <label class="block text-md font-medium text-black">City/Town</label>
                  <div class="mt-1">
                    <input type="text" id="city" v-model="city" name="city" :class="{'border-red-500': !city}" class="block w-full px-2 rounded-md border border-[#CBD6E2] sm:text-sm py-2">
                  </div>
                </div>

                <div>
                  <label class="block text-md font-medium text-black">Province</label>
                  <div class="mt-1">
                    <input type="text" id="province" v-model="province" name="province" :class="{'border-red-500': !province}" class="block w-full px-2 rounded-md border border-[#CBD6E2] sm:text-sm py-2">
                  </div>
                </div>

                <div>
                  <label class="block text-md font-medium text-black">Postal code</label>
                  <div class="mt-1">
                    <input type="text" id="postal-code" v-model="postalCode" name="postal-code" :class="{'border-red-500': !postalCode}" class="block w-full px-2 rounded-md border border-[#CBD6E2] sm:text-sm py-2">
                  </div>
                </div>
              </div>
              <span v-if="newAddressError" class="w-full mt-4 text-sm text-red">{{ newAddressError }}</span>
            </div>
          </div>
          <div v-if="addressState == 'AddNewAddress'" class="flex flex-row justify-between">
            <PrimarySubmit @click="cancelNewAddress()" buttonText="Cancel" class="text-white bg-rose-600 w-[45%] sm:w-[30%] mt-4"/>
            <PrimarySubmit @click="saveNewAddress()" buttonText="Save Address" class="w-[45%] sm:w-[30%] mt-4 text-white"/>
          </div>
        </div>
      </form>
      <div v-if="pudoloading && !changeAddress" class="flex flex-col mb-10 sm:mb-0 items-center space-y-4">
        <img class="animate-spin mt-12 w-10 mx-auto" src="../../public/img/progress.svg">
        <p class="text-md">Loading Pudo lockers</p>
      </div>
      <div v-else>
        <div class="mt-4 mx-auto max-w-2xl px-4 lg:max-w-none lg:px-0" v-if="closestLockers.length > 0 && !isSubmitClicked && !changeAddress">
          <h3 class="font-medium mb-2">Nearest Pudo Locker</h3>
          <ul class="bg-white rounded-md py-4 space-y-4">
            <li v-for="(lockerDistanceObj, index) in closestLockers" :key="lockerDistanceObj.locker.id">
              <div class="flex flex-row px-4 justify-between" :class="{'border-b': index !== closestLockers.length - 1}">
                <div class="flex flex-col">
                  <div class="flex flex-row items-center">
                    <input type="radio" :id="lockerDistanceObj.locker.id" :value="lockerDistanceObj.locker" v-model="selectedLocker" name="locker" class="form-radio h-4 w-4 text-blue-600">
                    <label :for="lockerDistanceObj.locker.id" class="ml-2 text-sm font-medium">{{ lockerDistanceObj.locker.name }}</label>
                  </div>
                  <p class="ml-6 text-xs pt-2" :class="{'pb-4': index !== closestLockers.length - 1}">{{ lockerDistanceObj.locker.address }}</p>
                </div>
                  <p class="text-sm font-medium whitespace-nowrap ml-1.5">{{ lockerDistanceObj.distance.toFixed(2) }} km</p>
              </div>
            </li>
          </ul>
        </div>
        <p v-if="lockerSelectError" class="text-red mt-1 text-sm">{{ lockerSelectError }}</p>
        <div class="flex flex-row justify-between items-center mx-auto max-w-2xl px-4 lg:max-w-none lg:px-0">
        <div></div>
        <PrimarySubmit @click="onLockerSelect()" v-if="closestLockers.length > 0 && !isSubmitClicked && !changeAddress" buttonText="OK" class="text-white w-[25%] mt-4 mb-4 sm:mb-0"/>
        </div>
      </div>
      <div v-if="isSubmitClicked" class="mx-auto max-w-2xl px-4 lg:max-w-none lg:px-0">
        <h3 class="font-medium mb-2 mt-4">Nearest Pudo Locker</h3>
        <div class="flex flex-row justify-between items-center">
          <div>
          <p class="text-sm font-medium mb-2">{{ selectedLocker.name }}</p>
          <p class="text-xs">{{ selectedLocker.address }}</p>
          </div>
          <p class="text-sm font-medium whitespace-nowrap ml-1.5">{{ selectedDistance.toFixed(2) }} km</p>
        </div>
      </div>
      <div class="mb-4 sm:mb-0 mx-auto max-w-2xl px-4 lg:max-w-none lg:px-0">
        <button
              v-show="isSubmitClicked"
              :disabled="cartItems.length == 0 && !isSubmitClicked && !emailAddress && !phoneNumber"
              @click="onSubmit()"
              :class="{
                'bg-green hover:opacity-80': !(cartItems.length == 0 && !isSubmitClicked && !selectedLocker && !emailAddress && !phoneNumber), 
                'bg-gray-400 cursor-default': cartItems.length == 0 && !isSubmitClicked && !selectedLocker && !emailAddress && !phoneNumber
              }"
              class="py-2.5 w-full mt-4 rounded-lg shadow-md text-white text-lg font-bold tracking-widest">
              PAYMENT
        </button>
      </div>
    </section>

      <section v-show="!cartSummaryConfirmed" aria-labelledby="summary-heading" class="pt-0 sm:pt-0 md:pt-0 md:px-10 lg:col-start-2 lg:row-start-1 lg:mx-auto lg:w-full lg:max-w-lg lg:bg-transparent lg:px-0 lg:pb-24 lg:pt-0 sm:mt-[-32px] md:mt-[-32px] lg:mt-0">
        <div class="mx-auto max-w-2xl px-4 lg:max-w-none lg:px-0">
          <h2 id="summary-heading" class="font-medium text-black text-xl sm:text-2xl mt-2 sm:mt-12 md:mt-12">Order Summary</h2>

          <p v-if="cartItems.length === 0" class="font-medium text-xl text-center mt-10">No items in cart</p>

        <div
          class="flex flex-row items-center space-x-4 mt-6"
          v-for="item in cartItems"
          :key="item.catalogueEntryGuid"
        >
          <div class="relative w-full border border-gray-400 bg-white rounded-md px-4">
            <div class="flex flex-row justify-between items-center py-3 rounded-lg">
              <span class="font-bold text-black text-xl">{{ itemDescriptions[item.catalogueEntryGuid] }}</span>
              <div class="flex flex-col space-y-3 items-center">
                <div class="flex space-x-5 justify-between items-center">
                  <img
                    v-if="item.quantity > 1"
                    class="w-4 cursor-pointer"
                    @click="decreaseItemQuantity(item)"
                    src="../../public/img/minus.svg"
                    alt="reduceQuantity"
                  >
                  <img v-else class="w-4" src="../../public/img/disable_minus.svg" alt="reduceQuantity">
                  <span class="w-4 text-center">{{ item.quantity }}</span>
                  <img
                    class="w-4 cursor-pointer"
                    @click="increaseItemQuantity(item)"
                    src="../../public/img/plus.svg"
                    alt="increaseQuantity"
                  >
                  <img
                    class="w-5 cursor-pointer"
                    @click="removeCartItem(item)"
                    src="../../public/img/cross.svg"
                    alt="removeItem"
                  >
                </div>
                <span class="text-black text-md font-semibold">R {{ itemPrices[item.catalogueEntryGuid] * item.quantity }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="h-[1.5px] mt-10 bg-black"></div>

        <div class="flex justify-between mt-10">
          <span class="text-black font-medium text-lg">Delivery Fee</span>
          <span class="text-black font-medium text-lg">Free</span>
        </div>

        <div class="flex justify-between mt-5">
          <span class="text-black font-semibold text-lg">Total</span>
          <span class="text-black font-semibold text-lg">R {{cartTotal}}</span>
        </div>

        <div class="flex items-end mt-10">
          <button
            @click="confirmCartSummary()"
            class="bg-green hover:opacity-80 py-2.5 w-full rounded-lg shadow-md text-white text-lg font-bold tracking-widest">CONTINUE</button>
        </div>
        </div>
      </section>
    </main>
  </MainLayout>
</template>

<script>
import MainLayout from '../components/Layouts/MainLayout.vue'
import ProgressStep from '../components/Atomic/AQuarks/ProgressStep.vue'
import PrimarySubmit from '../components/Atomic/AQuarks/PrimarySubmit.vue'
import { useUserStore} from "@/areas/users/stores/userStore";
import { useSessionStore } from "@/areas/sessions/stores/sessionStore";
import { useCatalogueStore} from "@/areas/catalogues/stores/catalogueStore";
import {defineComponent, onMounted, onUnmounted, onBeforeMount, ref, watch, computed } from 'vue';
import { useRouter } from 'vue-router'
import axios from 'axios';
import bus from '../bus'

export default {
  components: {
    MainLayout,
    ProgressStep,
    PrimarySubmit
  },

  setup() {
    const userStore = useUserStore();
    const sessionStore = useSessionStore();
    const catalogueStore = useCatalogueStore();

    const userAddressDtos = ref([])
    const lastUsedAddress = ref([])
    const cartItems = ref([])
    const itemDescriptions = ref([])
    const itemPrices = ref([])
    const userHasExistingAddress = ref(false)
    const pudoloading = ref(false)
    const closestLockers = ref([])
    const userLat = ref(0)
    const userLng = ref(0)
    const emailAddress = ref('')
    const phoneNumber = ref('')
    const addressId = ref(0)
    const streetAddress = ref('')
    const city = ref('')
    const province = ref('')
    const suburb = ref('')
    const postalCode = ref('')
    const isSubmitClicked = ref(false)
    const selectedLocker = ref(null)
    const selectedDistance = ref(0)
    const changeAddress = ref(false)
    const altAddress = ref([])
    const addressState = ref('')
    const userNewAddress = ref([])
    const userContactDtos = ref([])
    const userMobileContacts = ref([])
    const userEmailContacts = ref([])
    const lockerSelectError = ref(null)
    const cartSummaryConfirmed = ref(false)
    const router = useRouter();

    const init = async () => {
      let _initComplete = false;
      await validateSession();
      await getUserContacts();
      await getUserAddresses();
      _initComplete = true;
    };

    const validateSession = async () => {
      const session = await sessionStore.getOrCreateSession()
      if(session == null) {
        return;
      }
      cartItems.value = session.items;
      if(cartItems.value.length === 0) {
        router.push('/packages')
      }
      cartItems.value.sort((a, b) => a.catalogueEntryGuid.localeCompare(b.catalogueEntryGuid));
      return session;
    }

    watch(cartItems, async (newCartItems) => {
      for (let item of newCartItems) {
          itemDescriptions.value[item.catalogueEntryGuid] = await getCatalogueItemDesc(item.catalogueEntryGuid);
      }
    });

    watch(cartItems, async (newCartItems) => {
      for (let item of newCartItems) {
          itemPrices.value[item.catalogueEntryGuid] = await getCatalogueItemPrice(item.catalogueEntryGuid);
      }
    });

    async function getCatalogueItemDesc(guid) {
      const catalogueItem = await catalogueStore.getCatalogueEntry(guid);
      return catalogueItem.sku.descriptions[0].descValue;
    }

    async function getCatalogueItemPrice(guid) {
      const catalogueItem = await catalogueStore.getCatalogueEntry(guid);
      return catalogueItem.amount;
    }

    const cartTotal = computed(() => {
      let total = 0;
      for (let item of cartItems.value) {
        total += itemPrices.value[item.catalogueEntryGuid] * item.quantity;
      }
      return total;
    });

    async function getUserAddresses() {
      const addresses = await userStore.getUserAddress();
      userAddressDtos.value = addresses;
      
      if (userAddressDtos.value && userAddressDtos.value.length > 0) {
          userHasExistingAddress.value = true;
          addressState.value = "LastAddress"

          lastUsedAddress.value = userAddressDtos.value[0].addressDto;
          streetAddress.value = lastUsedAddress.value.street;
          city.value = lastUsedAddress.value.city;
          province.value = lastUsedAddress.value.province;
          suburb.value = lastUsedAddress.value.suburb;
          postalCode.value = lastUsedAddress.value.postalCode;
          userLat.value = lastUsedAddress.value.latitude
          userLng.value = lastUsedAddress.value.longitude
      }

      return addresses;
    }

    async function getUserContacts() {
      const contacts = await userStore.getUserContact();
      userContactDtos.value = contacts;

      userMobileContacts.value = [];
      userEmailContacts.value = [];

      userContactDtos.value.forEach(contactItem => {
        const contact = contactItem.contactDto;
        if (contact.contactTypeEnum === "Whatsapp") {
          userMobileContacts.value.push(contact);
        } else if (contact.contactTypeEnum === "Email") {
          userEmailContacts.value.push(contact);
        }
      });
    }

    async function getTerminals() {
      try {
        pudoloading.value = true;
        const response = await axios.get('https://ksttcgfunctionsv3.azurewebsites.net/api/tcg/terminal/get/all?code=FIS8Jv/rzfS1AOtrCjegCHBzSvRq2q7O956dFUTRNuanO/KO6BBHPA==');
        const terminals = response.data;
        pudoloading.value = false;
        return terminals;
      } catch (error) {
        console.error(error);
      }
    }

    async function findClosestTerminals(terminals, lat, lon) {
      const distances = terminals.map(terminal => {
        const distance = getDistance(lat, lon, terminal.latitude, terminal.longitude);
        return {
          locker: terminal,
          distance: distance
        };
      });

      distances.sort((a, b) => a.distance - b.distance);

      return distances.slice(0, 3);
    }

    watch(userLat, getClosestTerminals);
    watch(userLng, getClosestTerminals);

    async function getClosestTerminals() {
      const terminals = await getTerminals();
      closestLockers.value = await findClosestTerminals(terminals, userLat.value, userLng.value);
    }

    function getDistance(lat1, lon1, lat2, lon2) {
      let radlat1 = Math.PI * lat1/180;
      let radlat2 = Math.PI * lat2/180;
      let theta = lon1-lon2;
      let radtheta = Math.PI * theta/180;
      let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      dist = Math.acos(dist);
      dist = dist * 180/Math.PI;
      dist = dist * 60 * 1.1515 * 1.609344; // convert to kilometers
      return dist;
    }

    function onLockerSelect() {
      if(!selectedLocker.value) {
        lockerSelectError.value = "Please select a locker"
        return;
      }
      isSubmitClicked.value = true;
      lockerSelectError.value = null;
      selectedDistance.value = closestLockers.value.find(
        locker => locker.locker.id === selectedLocker.value.id
      ).distance;

      // Save the selectedLocker to local storage
      localStorage.setItem('selectedLocker', JSON.stringify(selectedLocker));
    }

    onMounted(() => {
      bus.on('cart-updated', async () => {
          await validateSession();
      });

      var autocomplete = new google.maps.places.Autocomplete(
        document.getElementById("autocomplete"),
      );

      autocomplete.setComponentRestrictions({
        country: ["za"]
        });

      autocomplete.addListener("place_changed", () => {
        streetAddress.value = '';
        let place = autocomplete.getPlace();
        userLat.value = place.geometry.location.lat();
        userLng.value = place.geometry.location.lng();
        let components = place.address_components;
        let area = '';
        isSubmitClicked.value = false;
        selectedLocker.value = null;

        let sublocalityLevel1Found = false;

        for (let i = 0; i < components.length; i++) {
          let componentType = components[i].types[0];

          switch (componentType) {
            case "street_number": // Street number
              streetAddress.value = `${components[i]['long_name']} `;
              break;
            case "route": // Street name
              streetAddress.value += components[i]['long_name'];
              document.getElementById('autocomplete').value = streetAddress.value;
              break;
            case "locality": // City
              document.getElementById('city').value = components[i]['long_name'];
              city.value = components[i]['long_name'];
              break;
            case "administrative_area_level_1": // Province
              document.getElementById('province').value = components[i]['long_name'];
              province.value = components[i]['long_name'];
              break;
            case "sublocality_level_1":
              document.getElementById('area').value = components[i]['long_name'];
              suburb.value = components[i]['long_name'];
              components[i]['long_name'] ? '' : sublocalityLevel1Found = true;
              break;
            case "postal_code":
              document.getElementById('postal-code').value = components[i]['long_name'];
              postalCode.value = components[i]['long_name'];
              break;
            case "sublocality_level_2":
              if (!sublocalityLevel1Found) {
                document.getElementById('area').value = components[i]['long_name'];
                suburb.value = components[i]['long_name'];
              }
              break;
          }
        }

        if (suburb.value == null || suburb.value === "") {
          suburb.value = city.value
        }
      });
    });

    onUnmounted(() => {
      bus.off('cart-updated');
    });

    onBeforeMount(async () => {
      await init();
    })

    return {
      // Stores
      userStore,
      sessionStore,
      catalogueStore,

      // Functions 
      validateSession,
      onLockerSelect,

      // Variables 
      userAddressDtos,
      lastUsedAddress,
      cartItems,
      itemDescriptions,
      itemPrices,
      userHasExistingAddress,
      pudoloading,
      closestLockers,
      userLat,
      userLng,
      cartTotal,
      addressId,
      streetAddress,
      city,
      province,
      suburb,
      postalCode,
      isSubmitClicked,
      selectedLocker,
      selectedDistance,
      changeAddress,
      altAddress,
      addressState,
      userNewAddress,
      userContactDtos,
      userMobileContacts,
      userEmailContacts,
      emailAddress,
      phoneNumber,
      lockerSelectError,
      cartSummaryConfirmed
    }
  },

  methods: {
    async removeCartItem(item) {
      const session = await this.validateSession();
      const response = await this.sessionStore.deleteCartItem(session.guid, item.catalogueEntryGuid)
      await this.validateSession();
      bus.emit('cart-updated');
    },

    async increaseItemQuantity(item) {
      const session = await this.validateSession();
      const newQuantity = item.quantity + 1;
      const response = await this.sessionStore.updateCart(item.guid, session.guid, newQuantity);
      await this.validateSession();
      bus.emit('cart-updated');
    },

    async decreaseItemQuantity(item) {
      const session = await this.validateSession();
      const newQuantity = item.quantity - 1;
      const response = await this.sessionStore.updateCart(item.guid, session.guid, newQuantity);
      await this.validateSession();
      bus.emit('cart-updated');
    },

    confirmCartSummary() {
      this.cartSummaryConfirmed = true;
    },

    triggerAddressChange() {
      this.addressState = "AddressHistory"
      this.changeAddress = true;
    },

    addNewAddress() {
      this.addressId = ""
      this.streetAddress = ""
      this.city = ""
      this.province = ""
      this.suburb = ""
      this.postalCode = ""
      this.userLat = ""
      this.userLng = ""

      this.addressState = "AddNewAddress"
      this.changeAddress = true;
    },

    newAddressSelected() {
      if(this.altAddress.length < 1) {
        this.altAddressError = "Please select an address"
        return;
      }
      this.addressId = this.altAddress.address.id;
      this.streetAddress = this.altAddress.address.street;
      this.city = this.altAddress.address.city;
      this.province = this.altAddress.address.province;
      this.suburb = this.altAddress.address.suburb;
      this.postalCode = this.altAddress.address.postalCode;
      this.userLat = this.altAddress.address.latitude;
      this.userLng = this.altAddress.address.longitude;
      this.addressState = "AltAddressSelected";
      this.changeAddress = false;
    },

    updateState(state) {
      this.addressState = state;
    },

    saveNewAddress() {
      const newAddress = {
        address: {
          id: 0,
          street: this.streetAddress,
          city: this.city,
          suburb: this.suburb,
          province: this.province,
          postalCode: this.postalCode,
          latitude: this.userLat,
          longitude: this.userLng
        },
      }

      if (!this.validateAddress(newAddress)) {
        this.newAddressError = "All address fields are required";
        return;
      }

      this.userNewAddress.push(newAddress);
      this.changeAddress = false;
      this.updateState("NewAddressAdded");
    },

    validateAddress(newAddress) {
      for (let key in newAddress.address) {
        if (!newAddress.address[key] && key !== "id") {
          return false;
        }
      }
      return true;
    },


    cancelNewAddress() {
      this.addressState = "AddressHistory";
    },

    onChange(type) {
      if (type === 'email') {
        this.showEmailInput = this.selectedEmail === 'other';

        if (!this.showEmailInput) {
            this.emailAddress = this.selectedEmail;
            this.customEmail = '';
        } else {
            this.emailAddress = this.customEmail;
        }
      } else {
        this.showPhoneInput = this.selectedPhone === 'other';

        if (!this.showPhoneInput) {
            this.phoneNumber = this.selectedPhone;
            this.customPhone = '';
        } else {
            this.phoneNumber = this.customPhone;
        }
      }
    },

    formatAndValidatePhone() {
        this.formatCellNumber();
        if (!this.validateCellNumber()) {
            this.phoneError = 'Please enter a valid South African number.';
        } else {
            this.phoneError = null;
        }
    },
    formatCellNumber() {
        if (this.customPhone.startsWith("0") && this.customPhone.length === 10) {
            this.customPhone = this.customPhone.replace("0", "+27");
            return true;
        } else if (this.customPhone.startsWith("+27") && this.customPhone.length === 12) {
            return true;
        }
        return false;
    },
    validateCellNumber() {
        const pattern = /^(\+27|0)[0-9]{9}$/;
        return pattern.test(this.customPhone);
    },

    validatePhone() {
        this.phoneError = null;

        if (!this.selectedPhone && !this.customPhone) {
            this.phoneError = 'Cellphone number is required';
            return false;
        }

        if (this.selectedPhone === 'other' && !this.customPhone) {
            this.phoneError = 'Please enter a phone number';
            return false;
        }

        return true;
    },

    validateEmail() {
      this.emailError = null;

      if (!this.selectedEmail && !this.customEmail) {
          this.emailError = 'Email address is required';
          return false;
      }

      if (this.selectedEmail === 'other' && !this.customEmail) {
          this.emailError = 'Please enter an email address';
          return false;
      }

      // If you want to validate email format
      const emailPattern = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      if (this.customEmail && !emailPattern.test(this.customEmail)) {
          this.emailError = 'Invalid email format';
          return false;
      }

      return true;
    },

    validateAddressFields() {
      return this.streetAddress && this.suburb && this.city && this.province && this.postalCode;
    },

    async onSubmit() {
      if (!this.validateAddressFields()) {
        this.newAddressError = 'Please fill all the address fields.'
        return;
      }
      if(!this.selectedLocker) {
        return;
      }
      if(!this.isSubmitClicked) {
        return;
      }
      if(this.showEmailInput)
      {
        this.emailAddress = this.customEmail;
      } else {
        this.emailAddress = this.selectedEmail;
      }

      if(this.showPhoneInput)
      {
        this.phoneNumber = this.customPhone;
      } else {
        this.phoneNumber = this.selectedPhone;
      }

      if (!this.validateEmail() || !this.validatePhone()) {
        return;
      }

      const address = {
        id: this.addressId ? this.addressId : 0,
        street: this.streetAddress,
        city: this.city,
        suburb: this.suburb,
        province: this.province,
        postalCode: this.postalCode,
        latitude: this.userLat,
        longitude: this.userLng
      }
      const emailContact = {
        id: this.showEmailInput ? 0 : this.emailAddress.id,
        contactTypeEnum: 0,
        value: this.showEmailInput ? this.emailAddress : this.emailAddress.value
      }
      const mobileContact = {
        id: this.showPhoneInput ? 0 : this.phoneNumber.id,
        contactTypeEnum: 1,
        value: this.showPhoneInput ? this.phoneNumber : this.phoneNumber.value
      }
      const session = await this.validateSession();
      const sessionGuid = session.guid;
      const externalId = this.selectedLocker.id;
      const response = await this.sessionStore.createDelivery(address, emailContact, mobileContact, sessionGuid, externalId)

      if(response) {
        const paymentIntentResponse = await this.sessionStore.createPaymentIntent(sessionGuid);
        if(paymentIntentResponse) {
          window.location.href = paymentIntentResponse.redirectUrl;
        }
      }
    }
  },
  watch: {
    userEmailContacts: {
      immediate: true,
      handler(newVal) {
          if (newVal.length > 0 && !this.selectedEmail) {
              this.selectedEmail = newVal[newVal.length - 1];
          }
      }
    },
    userMobileContacts: {
      immediate: true,
      handler(newVal) {
          if (newVal.length > 0 && !this.selectedPhone) {
              this.selectedPhone = newVal[newVal.length - 1];
          }
      }
    }
  },
  data() {
    return {
      selectedEmail: null,
      customEmail: '',
      showEmailInput: false,
      selectedPhone: null,
      customPhone: '',
      showPhoneInput: false,
      phoneError: null,
      emailError: null,
      newAddressError: null,
      altAddressError: null
    }
  }

}
</script>