<template>
  <MainLayout class="h-screen bg-blue-100" :showCart="false" navbarText="Payment">
    <div class="flex flex-col items-center justify-center w-1/2 mx-auto mt-48 space-y-4 overflow-none">
      <RadialProgress
          :diameter="200"
          :startColor="'#1EAB2D'"
          :stopColor="'#1EAB2D'"
          :innerStrokeColor="'#CBD6E2'"
          :completed-steps="completedSteps"
          :total-steps="15">
        <img class="w-full p-0.5" src="../../public/img/logo.svg">
      </RadialProgress>
      <p class="font-montserrat font-bold text-xl">LOADING</p>
    </div>
  </MainLayout>
</template>

<script>
import {defineComponent, onMounted, onUnmounted, onBeforeMount, ref, watch, computed} from 'vue';
import MainLayout from '../components/Layouts/MainLayout.vue'
import RadialProgress from "vue3-radial-progress";
import {useRoute, useRouter} from 'vue-router';
import {useSessionStore} from '@/areas/sessions/stores/sessionStore';

export default {
  components: {
    MainLayout,
    RadialProgress
  },

  setup() {
    const sessionStore = useSessionStore();
    const pollInterval = ref(null)
    const route = useRoute();
    const router = useRouter();
    const paymentStatus = ref('');
    const sessionGuid = ref('');
    const pollCount = ref(0);
    const loading = ref(true);
    const completedSteps = ref(0);
    const stepsIncrementInterval = ref(null);

    const init = async () => {
      let _initComplete = false;
      paymentStatus.value = route.params.status;
      sessionGuid.value = route.params.sessionGuid;

      await checkSessionState();

      startPolling();
      _initComplete = true;
    }

    const checkSessionState = async () => {
      try {
        const session = await sessionStore.getSessionByGuid(sessionGuid.value);

        switch (session.sessionState) {
          case "AwaitingPayment":
            pollCount.value++;

            if (pollCount.value >= 3 || paymentStatus.value != 'success') {

              clearInterval(pollInterval.value);
              await router.push('/history');
            }

            break;
          case "AwaitingDelivery":
            clearInterval(pollInterval.value);

            if (paymentStatus.value != 'success') {
              await router.push('/history');
              break;
            }

            await router.push('/confirmation');
            break;
          default:
            break;
        }
      } catch (error) {
        console.error(error);
      }
    }

    const startPolling = () => {
      pollCount.value = 0;
      pollInterval.value = setInterval(checkSessionState, 5000);

      stepsIncrementInterval.value = setInterval(() => {
        completedSteps.value++;
      }, 1000);
    }


    onBeforeMount(async () => {
      await init();
    })

    onUnmounted(() => {
      if (pollInterval.value) {
        clearInterval(pollInterval.value);
      }

      if (stepsIncrementInterval.value) {
        clearInterval(stepsIncrementInterval.value);
      }
    });

    return {
      sessionStore,
      pollInterval,
      completedSteps,
      loading
    }
  },
}
</script>