import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full rounded-lg bg-white drop-shadow-lg p-6" }
const _hoisted_2 = { class: "flex items-center justify-between gap-x-4" }
const _hoisted_3 = { class: "text-2xl font-bold font-montserrat" }
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.title), 1),
      _createElementVNode("img", {
        class: "h-10",
        src: _ctx.imageUri
      }, null, 8, _hoisted_4)
    ]),
    _renderSlot(_ctx.$slots, "default")
  ]))
}