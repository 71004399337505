<template>
  <div v-if="isVisible" class="relative z-30" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"  @click="closeModalOnOverlayClick"></div>
    <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
      <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
        <div class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
          <button @click="closeModal" class="absolute top-4 right-4 text-gray-500 hover:text-gray-700 cursor-pointer focus:outline-none">
            <svg @click="closeModal" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
            </svg>
          </button>
          <div>
            <div class="flex justify-center pt-4">
              <img class="h-20" :src="image" alt="">
            </div>
            <div class="mt-3 text-center sm:mt-5 pb-10">
              <h3 class="text-2xl font-semibold leading-6">{{ title }}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  props: {
    isVisible: Boolean,
    title: String,
    image: String,
  },
  methods: {
    closeModal() {
      this.$emit('close-modal');
    },
    closeModalOnOverlayClick() {
      this.closeModal();
    },
  },
  mounted() {
    if (this.isVisible) {
      document.addEventListener('click', this.closeModalOnOverlayClick);
    }
  },
  beforeUnmount() {
    document.removeEventListener('click', this.closeModalOnOverlayClick);
  },
}
</script>
