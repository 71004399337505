import {defineStore} from "pinia";
import {ReferralProxy} from "@/areas/referrals/proxies/referralProxy";
import {OperationResult, Operations} from "@/helpers/responses/models/operationResult";
import {UserRevenueDto} from "@/areas/referrals/dtos/userRevenueDto";
import {ReferralBalances} from "@/areas/referrals/dtos/referralBalances";
import {calculateCycleDates} from "@/helpers/cycleCalculation";

interface State {
    updatedAt: Date | null
    userRevenues: UserRevenueDto[] | null
    proxy: ReferralProxy
    from: Date | null
    to: Date | null
}

export const useReferralStore = defineStore({
    id: "referral-store",
    state: (): State => ({
        updatedAt: null,
        userRevenues: null,
        from: null,
        to: null,
        proxy: new ReferralProxy(),
    }),
    actions: {
        async fetchDownlines(from: Date, to: Date): Promise<OperationResult<UserRevenueDto[]>> {
            const result = await this.proxy.GetDownlines(from, to);

            if (result.isSuccessful) {
                this.updatedAt = new Date()
                this.userRevenues = result.content!
            }

            return result
        },

        async calculateReferralBalances (from: Date, to: Date): Promise<ReferralBalances> {
            if (!this.userRevenues) {
                const [start, end] = calculateCycleDates(new Date())

                await this.fetchDownlines(start, end)
            }

            const userRevenues = this.userRevenues

            const balance: ReferralBalances = {
                ogr: 0,
                sales: 0,
                referrals: 0
            }

            userRevenues!.forEach(userRevenue => {
                balance.referrals += 1

                userRevenue.entityAllocations.forEach(entityAllocation => {
                    if (entityAllocation.allocation.revenueType == "Ogr") {
                        balance.ogr += entityAllocation.amount
                    } else {
                        balance.sales += entityAllocation.amount
                    }
                })
            })

            return balance
        },

        forceRefresh(): void {
            this.updatedAt = null
            this.userRevenues = null
        }
    }
})
