<template>
  <MainLayout navbarText="Choose Package">
    <CartNotification :showNotification="showCartNotification" @close="showCartNotification = false"/>
    <div class="mx-auto mt-12 w-[92%] sm:w-[60%] md:w-[90%] lg:w-[88%] max-w-screen-xl bg-blue-100">
      <div class="flex flex-col justify-center">
        <ProgressStep :currentStep=2 />
      </div> 
      <PackageCard @package-added-to-cart="handlePackageAddedToCart" />
      <div class="flex justify-end">
        <PrimarySubmit @click="navigateToCheckout()" buttonText="Go to Checkout" class="text-white w-full sm:w-full md:w-[30%] lg:w-[20%] mt-6 mb-6"/>
      </div>
    </div>
  </MainLayout>
</template>

<script>
import MainLayout from '../components/Layouts/MainLayout.vue'
import ProgressStep from '../components/Atomic/AQuarks/ProgressStep.vue'
import PackageCard from '../components/Atomic/AQuarks/PackageCard.vue'
import PrimarySubmit from '../components/Atomic/AQuarks/PrimarySubmit.vue';
import CartNotification from '../components/Atomic/AQuarks/CartNotification.vue';

export default {
  components: {
    MainLayout,
    ProgressStep,
    PackageCard,
    PrimarySubmit,
    CartNotification
  },
  data() {
    return {
      showCartNotification: false,
    };
  },
  methods: {
    navigateToCheckout() {
      this.$router.push('/checkout')
    },
    handlePackageAddedToCart() {
      this.showCartNotification = true;
      setTimeout(() => {
        this.showCartNotification = false;
      }, 2000);
    },
  },
};
</script>