import {defineStore} from "pinia";
import {OperationResult} from "@/helpers/responses/models/operationResult";
import {WalletProxy} from "@/areas/wallets/proxies/walletProxy";
import {WalletDto} from "@/areas/wallets/dtos/walletDto";

export const useWalletStore = defineStore({
    id: "wallets",
    state: () => ({
        proxy: new WalletProxy(),
    }),
    actions: {
        async Get() : Promise<OperationResult<WalletDto>> {
            return await this.proxy.get()
        }
    }
})