
import {defineComponent, ref} from 'vue';
import {useReferralStore} from "@/areas/referrals/stores/referralStore";
import DashLayout from '../components/Layouts/DashLayout.vue'
import {calculateCycleDates} from "@/helpers/cycleCalculation";
import {UserDto} from "@/areas/users/dtos/userDto";
import {EntityAllocationDto} from "@/areas/allocations/dtos/entityAllocationDto";
import ReferralCard from "@/components/Atomic/CMolecules/ReferralCard.vue";
import DateInput from "@/components/Atomic/AQuarks/InputDate.vue";

interface RevenueTotal {
  ogr: number,
  sales: number,
  total: number
}

interface AllocationLine {
  entityAllocations: EntityAllocationDto[],
  user?: UserDto
  total: RevenueTotal
}

export default defineComponent({
  components: {
    DateInput,
    DashLayout,
    ReferralCard
  },
  async setup() {
    const sidebarOpen = ref<boolean>(false);

    const referralStore = useReferralStore();

    let directReferrals = ref<AllocationLine[]>([]);

    let someRandomUser = ref<UserDto>();

    let indirectReferralCount = ref<number>(0);

    let indirectReferralTotal = ref<RevenueTotal>({
      ogr: 0,
      sales: 0,
      total: 0
    });

    let directReferralTotal = ref<RevenueTotal>({
      ogr: 0,
      sales: 0,
      total: 0
    })

    const startDate = ref<Date>(new Date())

    const endDate = ref<Date>(new Date())

    //Errors
    const startDateError = ref<string>('')
    const endDateError = ref<string>('')

    async function startup(): Promise<void> {
      const currentDate = new Date()

      let [start, end] = calculateCycleDates(currentDate);

      startDate.value = new Date('2023-09-01')
      endDate.value = end

      await calculateData(startDate.value, endDate.value)
    }

    async function changeStartDate (start: Date) {
      startDateError.value = ''
      endDateError.value = ''

      if (start > endDate.value) {
        startDateError.value = "The start date cannot be after the end date"
      }

      startDate.value = start

      await calculateData( start, endDate.value);
    }

    async function calculateData (start: Date, end: Date) {
      const allocationsResult = await referralStore.fetchDownlines(start, end);

      if (!allocationsResult.isSuccessful) {

        //Todo need to redirect to an error screen here
        return
      }

      const referralItems = allocationsResult.content!

      referralItems.forEach(revenueLevelDto => {

        if (revenueLevelDto.user) {
          someRandomUser.value = revenueLevelDto.user;
        }

        const revenueTotal = calculateAllocationTotal(revenueLevelDto.entityAllocations)

        if (revenueLevelDto.level === 1) {
          const allocationLine: AllocationLine = {
            total: revenueTotal,
            entityAllocations: revenueLevelDto.entityAllocations,
            user: revenueLevelDto.user
          }

          directReferrals.value.push(allocationLine);
        }

        if (revenueLevelDto.level > 1) {
          indirectReferralTotal.value.total += revenueTotal.total
          indirectReferralTotal.value.sales += revenueTotal.sales
          indirectReferralTotal.value.ogr += revenueTotal.ogr

          indirectReferralCount.value += 1
        }
      });

      directReferralTotal.value = calculateAllocationLinesTotal(directReferrals.value)
    }

    function calculateAllocationTotal(allocations: EntityAllocationDto[]): RevenueTotal {
      const total: RevenueTotal = {
        ogr: 0,
        sales: 0,
        total: 0
      }

      return allocations
          .reduce((referralTotal, entityAllocationDto) => {
            if (entityAllocationDto.allocation.revenueType == "Ogr") {
              referralTotal.ogr += entityAllocationDto.amount
            } else {
              referralTotal.sales += entityAllocationDto.amount
            }

            referralTotal.total = referralTotal.ogr + referralTotal.sales

            return referralTotal
          }, total)
    }

    function calculateAllocationLinesTotal(allocationLines: AllocationLine[]): RevenueTotal {
      const total: RevenueTotal = {
        ogr: 0,
        sales: 0,
        total: 0
      }

      allocationLines.reduce((total, allocationLine) => {
        total.total += allocationLine.total.total
        total.sales += allocationLine.total.sales
        total.ogr += allocationLine.total.ogr

        return total;
      }, total)

      return total;
    }

    await startup();

    return {
      sidebarOpen,
      referralStore,
      directReferrals,
      directReferralTotal,
      indirectReferralCount,
      indirectReferralTotal,
      someRandomUser,
      startDate,
      endDate,
      startDateError,
      endDateError,

      changeStartDate
    }
  }
})
