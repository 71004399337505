<template>
  <AdminPortalSidebar pageHeader="Order Fulfillment"/>

  <div class="relative min-h-screen bg-blue-100 font-montserrat pb-24">
    <div class="space-y-2">
      <div @click="toggleDropdown" class="group flex flex-col gap-2 rounded-lg shadow-lg bg-white py-3.5 text-black mx-8 relative top-10 mb-6" tabindex="2">

        <!-- Customer Order -->
        <div class="flex cursor-pointer items-center justify-between px-3">
          <span class="font-semibold"> Order ID: WOW00000001 </span>
          <img src="../../public/img/Arrow_down.svg" class="h-2 w-3 transition-all duration-200" :class="{ '-rotate-180': isDropdownVisible }"/>
        </div>
        <div v-if="isDropdownVisible" class="invisible h-auto max-h-0 items-center opacity-0 transition-all group-focus:visible group-focus:max-h-screen group-focus:opacity-100 group-focus:duration-1000">
          <div class="ml-3 mt-5 mb-2">
            <h1 class="font-bold text-xl underline">Customer Order</h1>
          </div>
          <div class="block p-3 ml-2.5">
            <span class="font-semibold">Created At:  </span>
            <span class="font-medium ml-2">2023-09-05 21:40:49 </span>
          </div>
          <div class="block p-3 bg-gray-200 w-full">
            <span class="font-semibold ml-2.5">Street Address: </span>
            <span class="font-medium ml-2">23 Rembrandt Cresent Sonstraal Durbanville, Western Cape, 7550 </span>
          </div>
          <div class="block p-3 ml-2.5 w-full">
            <span class="font-semibold">Full Name: </span>
            <span class="font-medium ml-2">John Doe </span>
          </div>
          <div class="block p-3 bg-gray-200 w-full">
            <span class="font-semibold ml-2.5">Email: </span>
            <span class="font-medium ml-2">john@example.com </span>
          </div>
          <div class="block p-3 ml-2.5 w-full">
            <span class="font-semibold">Contact Number: </span>
            <span class="font-medium ml-2">021 123 1234 </span>
          </div>
          <div class="block p-3 bg-gray-200 w-full">
            <span class="font-semibold ml-2.5">Product: </span>
            <div>
              <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  <table class="mt-2 ml-2.5">
                    <thead>
                      <tr>
                        <th scope="col" class="font-medium py-2 pl-4 pr-3 text-left text-sm sm:pl-0 underline">Product Name</th>
                        <th scope="col" class="font-medium px-3 py-2 text-left text-sm underline">Product Code</th>
                        <th scope="col" class="font-medium px-3 py-2 text-left text-sm underline">Amount</th>
                        <th scope="col" class="font-medium px-3 py-2 text-left text-sm underline">Quantity</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="whitespace-nowrap py-2.5 pl-4 pr-3 text-sm sm:pl-0 font-medium">Starter</td>
                        <td class="whitespace-nowrap px-3 py-2.5 text-sm font-medium">CA-SIMB-105-10A-300D</td>
                        <td class="whitespace-nowrap px-3 py-2.5 text-sm font-medium">R500</td>
                        <td class="whitespace-nowrap px-3 py-2.5 text-sm font-medium">2</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
    
</template>

<script>
import AdminPortalSidebar from '../components/Layouts/AdminPortalSidebar.vue';

export default {
  components: {
    AdminPortalSidebar
  },

  data() {
    return {
      isDropdownVisible: false,
    }
  },

  methods: {
    toggleDropdown() {
      this.isDropdownVisible = !this.isDropdownVisible
    },
  }
}
</script>

