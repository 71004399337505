import {OperationResult} from "@/helpers/responses/models/operationResult";
import axiosInstance from "@/axiosConfig";
import {Operations} from "@/helpers/responses/operations";
import {AxiosError} from "axios";
import {ProblemDetails} from "@/helpers/responses/models/problemDetails";
import {WalletDto} from "@/areas/wallets/dtos/walletDto";

export class WalletProxy
{
    public async get(): Promise<OperationResult<WalletDto>> {
        const uri = '/wallets'

        try {
            const response = await axiosInstance.get<WalletDto>(uri)

            return Operations.ToSuccessResponse(response.data)
        } catch (error) {
            console.error(error);

            if (error instanceof AxiosError) {

                if (error.response && error.response.data) {
                    var parsedError = error.response.data as ProblemDetails

                    return Operations.ToErrorResponseFromProblemDetails(parsedError);
                }

                return Operations.ToErrorResponse("RequestFailure");
            }

            throw error
        }
    }
}