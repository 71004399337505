import {defineStore} from 'pinia'
import { SessionDto } from '@/areas/sessions/dtos/sessionDto';
import { ItemFlatDto } from '@/areas/items/dtos/itemFlatDto';
import { CatalogueProxy } from '@/areas/catalogues/proxies/catalogueProxy';

export const useCatalogueStore = defineStore({
    id: "catalogue",
    state: () => ({
        updated_at: new Date(),
        session: null as SessionDto | null,
        items: [] as ItemFlatDto[] | null,
        proxy: new CatalogueProxy,
    }),
    actions: {

        async getCatalogue() {
            if (this.proxy == null) {
                return null;
            }
            const catalogue = await this.proxy.getCatalogue();

            if(!catalogue) {
                return null;
            }
            return catalogue;
        },

        async getCatalogueEntry(itemGuid: string) {
            if (this.proxy == null) {
                return null;
            }
            const catalogueEntry = await this.proxy.getCatalogueEntry(itemGuid);
        
            if(!catalogueEntry){
                return null;
            }
        
            return catalogueEntry;
        },        
    }
})