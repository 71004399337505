
import {defineComponent, onMounted, ref} from 'vue';
import {useUserStore} from "@/areas/users/stores/userStore";
import router from "@/router";
import DashLayout from '@/components/Layouts/DashLayout.vue'
import HeaderCard from "@/components/Atomic/BAtoms/HeaderCard.vue";
import {useWalletStore} from "@/areas/wallets/stores/walletStore";
import {UserDto} from "@/areas/users/dtos/userDto";
import {useReferralStore} from "@/areas/referrals/stores/referralStore";
import {calculateCycleDates} from "@/helpers/cycleCalculation";
import {ReferralBalances} from "@/areas/referrals/dtos/referralBalances";

interface WalletBalance {
  total: number,
  available: number
}

export default defineComponent({
  components: {
    HeaderCard,
    DashLayout
  },
  async setup() {
    onMounted(async () => {
      window.scrollTo(0, 0);
    })

    const showCopiedMessage = ref<boolean>(false)

    const user = ref<UserDto>()

    const balance = ref<WalletBalance | null>()

    const referralBalance = ref<ReferralBalances | null>(null)

    const userStore = useUserStore()

    const walletStore = useWalletStore()

    const referralStore = useReferralStore()

    const validateUser = async () => {
      const userResponse = await userStore.getUser()

      if (userResponse == null) {
        router.push("/register")
      }

      user.value = userResponse
      return user;
    }

    function copyReferralLink() {
      if (user.value) {
        const textToCopy = `${process.env.VUE_APP_API_BASEURL}/register/${user.value.affiliateCode}`

        navigator.clipboard.writeText(textToCopy)
            .then(() => {
              showCopiedMessage.value = true;
            })
            .catch((err) => {
              console.error('Unable to copy to clipboard: ', err);
            })

        setTimeout(() => {
          showCopiedMessage.value = false;
        }, 2000)
      }
    }

    async function getWalletInfo(): Promise<void> {
      const walletResult = await walletStore.Get()

      if (!walletResult.isSuccessful) {
        return
      }

      const wallet = walletResult.content!

      const available = wallet.accounts.find(account => account.accountType == "Available")
      const holding = wallet.accounts.find(account => account.accountType == "Holding")

      if (available && holding) {
        balance.value = {
          available: available.balance,
          total: available.balance + holding.balance
        }
      }
    }

    async function getReferralBalances (): Promise<void> {
      const [start, end] = calculateCycleDates(new Date())

      await referralStore.fetchDownlines(start, end)

      referralBalance.value = await referralStore.calculateReferralBalances(start, end)
    }

    await validateUser()
    await getWalletInfo()
    await getReferralBalances()

    return {
      showCopiedMessage,
      balance,
      referralBalance,

      copyReferralLink
    }
  }
});
