<template>
  <button type="button"
          class="inline-flex justify-center items-center gap-x-2 rounded-xl drop-shadow-sm w-[80px] bg-gray-200 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-gray-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2">
    <img src="../../../../public/img/cart.svg" class="h-6" alt="checkout">
    <p class="text-black font-montserrat">{{ cartQuantity }}</p>
  </button>
</template>

<script>
export default {
  props: {
    cartQuantity: {
      type: Number,
      default: 0
    }
  }
}
</script>