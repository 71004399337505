<template>
<MainLayout navbarText="Order Completed" :showCart="false">
<div class="mx-auto w-full sm:w-2/3 md:w-2/3 3xl:w-1/2 2k:w-1/2 bg-blue-100">
    <div class="flex flex-row justify-between">
        <img class="hidden xl:block lg:w-[40%]" src="../../public/img/celebrate.svg">
        <div class="px-4 pb-4 pt-2">
            <div class="">
                <p class="text-center xl:text-left my-4 text-2xl font-semibold font-montserrat text-black sm:text-2xl">Payment Successful</p>
                <img class="block xl:hidden w-[80%] md:w-[60%] mx-auto" src="../../public/img/celebrate.svg">
                <p class="mt-4 text-center xl:text-left font-medium text-sm font-montserrat text-black">We are processing your order. You will be notified as soon as your package has been shipped.</p>

                <dl class="mt-4 text-center xl:text-left font-montserrat text-sm">
                    <dt class="text-gray-900 font-semibold">Order number:</dt>
                    <dd class="mt-2 text-black font-semibold text-lg">WOW{{ session.id ? formatId(session.id) : '' }}</dd>
                </dl>

                <p class="mt-4 text-center xl:text-left text-xl font-medium font-montserrat text-black sm:text-xl">Order Summary</p>

                <div 
                class="flex flex-row items-center space-x-4 mt-6" 
                v-for="item in cartItems" 
                :key="item.catalogueEntryGuid"
                >
                <div class="relative font-montserrat w-full border border-gray-400 bg-white rounded-md px-4">
                    <div class="flex flex-row justify-between items-center py-3 rounded-lg">
                    <span class="font-bold text-black text-xl">{{ itemDescriptions[item.catalogueEntryGuid] }}</span>
                    <div class="flex flex-col space-y-3 items-end">
                        <span class="text-black text-md font-semibold">Qty: {{ item.quantity }}</span>
                        <span class="text-black text-md font-semibold">R{{ formattedPrice(String(itemPrices[item.catalogueEntryGuid] * item.quantity)) }}</span> 
                    </div>
                    </div>
                </div>
                </div>

                <dl class="space-y-2 pt-6 text-sm font-medium font-montserrat text-black">            

                    <div class="flex justify-between">
                    <dt class="font-medium">Delivery Fee</dt>
                    <dd class="text-black font-medium">Free</dd>
                    </div>            

                    <div class="flex items-center justify-between pt-2 text-gray-900">
                    <dt class="font-semibold">Total</dt>
                    <dd class="font-semibold">R{{ formattedPrice(String(cartTotal + '.00' || '0.00')) }}</dd>
                    </div>
                </dl>

                <dl class="flex flex-row flex-wrap font-montserrat justify-between items-center mt-6 gap-x-4 text-black">
                    <div>
                    <dt class="text-black text-md font-semibold">Delivery Address</dt>
                        <dd class="text-sm mt-2">   
                            <address class="not-italic font-medium">
                                <span class="block">{{ selectedLockerName || 'Could not find Name' }}</span>
                                <span class="block">{{ selectedLockerAddress || 'Could not find Address' }}</span>
                                <span class="block">{{ selectedLockerPostalCode || 'Could not find Postal Code' }}</span>
                            </address>
                        </dd>
                    </div>
                    <div class="mt-4 w-full sm:w-[140px] sm:ml-auto">                
                        <PrimarySubmit @click="redirectOrderHistory()" buttonText="Go to Orders" class="text-white w-full"/>
                    </div>
                </dl>  
            </div>
        </div>
    </div>
</div>
</MainLayout>
</template>

<script>
import MainLayout from '../components/Layouts/MainLayout.vue'
import PrimarySubmit from '../components/Atomic/AQuarks/PrimarySubmit.vue'
import {defineComponent, onMounted, onUnmounted, onBeforeMount, ref, watch, computed } from 'vue';
import { useSessionStore } from "@/areas/sessions/stores/sessionStore";
import { useCatalogueStore} from "@/areas/catalogues/stores/catalogueStore";

export default {
    components: {
        MainLayout,
        PrimarySubmit
    },
    data() {
        return {
            
        }
    },
    setup() {
        const sessionStore = useSessionStore();
        const catalogueStore = useCatalogueStore();

        const cartItems = ref([])
        const session = ref([]);
        const itemPrices = ref([])
        const itemDescriptions = ref([])

        const selectedLockerName = ref(null);
        const selectedLockerAddress = ref(null);
        const selectedLockerPostalCode = ref(null);
        
        const init = async () => {
            let _initComplete = false;
            await validateSession();
            _initComplete = true;
        }

        const sessionGuid = localStorage.getItem('sessionGuid');

        const validateSession = async () => {
            const sessionResponse = await sessionStore.getSessionByGuid(sessionGuid);

            if(sessionResponse == null) {
                return;
            }
            session.value = sessionResponse;
            cartItems.value = session.value.items;

            return sessionResponse;
        }

        watch(cartItems, async (newCartItems) => {
            for (let item of newCartItems) {
                itemDescriptions.value[item.catalogueEntryGuid] = await getCatalogueItemDesc(item.catalogueEntryGuid);
            }
        });

        watch(cartItems, async (newCartItems) => {
            for (let item of newCartItems) {
                itemPrices.value[item.catalogueEntryGuid] = await getCatalogueItemPrice(item.catalogueEntryGuid);
            }
        });

        async function getCatalogueItemDesc(guid) {
            const catalogueItem = await catalogueStore.getCatalogueEntry(guid);
            return catalogueItem.sku.descriptions[0].descValue;
        }
            
        async function getCatalogueItemPrice(guid) {
            const catalogueItem = await catalogueStore.getCatalogueEntry(guid);
            return catalogueItem.amount;
        }

        const cartTotal = computed(() => {
            let total = 0;
            for (let item of cartItems.value) {
                total += itemPrices.value[item.catalogueEntryGuid] * item.quantity;
            }
            return total;
        });

        onBeforeMount(async () => {
            await init();
        })

        onMounted(() => {
            // Retrieve the selectedLocker from local storage
            const selectedLocker = JSON.parse(localStorage.getItem('selectedLocker'));
            selectedLockerName.value = selectedLocker._value.name;
            selectedLockerAddress.value = selectedLocker._value.address;
            selectedLockerPostalCode.value = selectedLocker._value.place.postalCode;
        });

        return {sessionStore, catalogueStore, session, validateSession, cartTotal, cartItems, itemDescriptions, itemPrices, selectedLockerName, selectedLockerAddress, selectedLockerPostalCode}
    },
    methods: {
        redirectOrderHistory() {
            this.$router.push('/history')
        },
        formatId(id) {
            return id.toString().padStart(6, '0');
        },
        formattedPrice(price) {
            const priceParts = price.split(".");
            let integerPart = priceParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");

            // Remove the space after the currency symbol
            if (integerPart.startsWith('R ')) {
                integerPart = 'R' + integerPart.substring(2);
            }
            return integerPart + (priceParts[1] ? "." + priceParts[1] : "");
        },
    }

}

</script>