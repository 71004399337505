
import {computed, defineComponent, PropType, ref, toRefs, watch} from 'vue';

export default defineComponent({
  props: {
    initialDate: {
      type: Date as PropType<Date>,
      required: false
    },
    error: {
      type: String as PropType<string>,
      required: false
    }
  },
  emits: {
    dateChanged: (date: Date) => true,
  },
  setup(props, {emit}) {
    const {initialDate} = toRefs(props);

    const formattedDate = computed(() => {
      if (initialDate.value) {
        console.log(initialDate.value.toString());

        return initialDate.value.toISOString().substring(0, 10);
      }
    });

    const updateDate = (event: Event) => {
      const target = event.target as HTMLInputElement;

      const newDate = new Date(target.value);
      emit('dateChanged', newDate);
    };

    return {
      formattedDate,
      updateDate
    };
  }
});
