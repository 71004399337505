

import {ScanDto} from "@/areas/fulfillment/dtos/scanDto";
import {defineComponent, PropType} from "vue";

export default defineComponent({
  props: {
    scanDto: {
      type: Object as () => ScanDto,
      required: true
    }
  },
  emits: {
    deleteScan: (scanGuid: string) => true
  },
  setup(prop, ctx) {

    const handleDeleteScan = () => {
      ctx.emit('deleteScan', prop.scanDto.guid)
    }

    return {
      handleDeleteScan
    }
  }
});
