<template>
  <div class="min-h-screen bg-blue-100">
    <NavBar :showCart="showCart" :pageHeader="navbarText"/>
    <main class="bg-blue-100">
      <slot></slot>
    </main>
  </div>
</template>

<script>
import NavBar from '../Atomic/BAtoms/NavBar.vue'
import {useUserStore} from "@/areas/users/stores/userStore";
import {onBeforeMount, ref} from 'vue';
import router from "@/router";

export default {
    components: {
        NavBar
    },
    props: {
      navbarText: {
        type: String
      },
      showCart: {
        type: Boolean,
        default: true
      }
    },
    setup() {
      const user = ref([])

      const userStore = useUserStore();

      const init = async () => {
        let _initComplete = false;
        await validateUser();
        _initComplete = true;
      };

      const validateUser = async () => {
        const userResponse = await userStore.getUser()

        if (userResponse == null) {
          router.push("/register")
        }

        user.value = userResponse;
        return user;

      }

      onBeforeMount(async () => {
        await init();
      })

      return {user, userStore};
    }
}
</script>