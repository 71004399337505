

import {computed, defineComponent, ref} from "vue";
import ScanLine from "@/components/Atomic/AQuarks/ScanLine.vue";
import PrimarySubmit from "@/components/Atomic/AQuarks/PrimarySubmit.vue";
import {FulfillmentDto} from "@/areas/fulfillment/dtos/fulfillmentDto";
import {DeliveryInfoDto} from "@/areas/sessions/dtos/deliveryInfoDto";
import DeliveryInformation from "@/components/Atomic/AQuarks/DeliveryInformation.vue";

export default defineComponent({
  components: {
    DeliveryInformation,
    ScanLine,
    PrimarySubmit,
  },
  props: {
    fulfillmentDto: {
      type: Object as () => FulfillmentDto,
      required: true
    },
    orderNumber: {
      type: String,
      required: true
    },
    deliveryDto: {
      type: Object as () => DeliveryInfoDto,
      required: true
    },
    error: {
      type: String,
      required: false
    },
  },
  emits: {
    scan: (fulfillmentGuid: string, barcode: string) => true,
    deleteScan: (fulfillmentGuid: string, barcode: string) => true,
    cancel: (fulfillment: string) => true,
    process: (fulfillment: string) => true,
  },
  setup(props, ctx) {
    const barcode = ref<string>('');

    const loading = ref(false);
    const buttonText = computed(() => (loading.value ? "Loading..." : 'Scan'));

    const isScanButtonDisabled = computed(() => barcode.value.length === 0);

    const isProcessButtonDisabled = computed(() => !props.fulfillmentDto || props.fulfillmentDto.scans.length === 0)

    const remainingCounter = computed(() => {

      if (props.fulfillmentDto) {
        const totalScanned = props.fulfillmentDto.scans.reduce((acc, curr) => acc + curr.itemCount, 0);

        return props.fulfillmentDto.unitsRequired - totalScanned;
      }

      return 0
    })

    const handleScanEvent = () => {
      if (!barcode.value || barcode.value.trim().length === 0) {
        return;
      }

      loading.value = true;

      ctx.emit("scan", props.fulfillmentDto.guid, barcode.value);
      barcode.value = "";
      loading.value = false;
    }

    function handleDeleteScanEvent(scanGuid: string) {
      ctx.emit('deleteScan', props.fulfillmentDto.guid, scanGuid)
    }

    const handleCancelEvent = () => {
      ctx.emit('cancel', props.fulfillmentDto.guid)
    }

    const handleProcessEvent = () => {
      ctx.emit('process', props.fulfillmentDto.guid)
    }

    function processError(): string {
      if (props.error === undefined) {
        return ""
      }

      switch (props.error) {
        case "FulfillmentIncorrectState":
          return "The fulfillment has already been completed or cancelled, please close and try again"
        case "InventoryItemNotFound":
          return "The item you scanned has either already been allocated to another order, or it does not exist"
        case "InventoryItemAlreadyFulfilled":
          return "The item you scanned has either already been allocated to another order, or it does not exist"
        case "FulfillmentNotComplete":
          return "Items remaining must be 0 before processing the order"
        default:
          return "An unexpected error occurred"
      }
    }

    return {
      barcode,
      loading,
      buttonText,
      isScanButtonDisabled,
      isProcessButtonDisabled,
      remainingCounter,
      deliveryInfoDto: props.deliveryDto,
      handleScanEvent,
      handleProcessEvent,
      handleCancelEvent,
      handleDeleteScanEvent,
      processError
    };
  }
});
