
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'card-wrapper',
  props: {
    title: {
      required: true,
      type: String
    },
    imageUri: {
      required: true,
      type: String
    }
  },

});
