import axiosInstance from "@/axiosConfig";
import {OperationResult, Operations} from "@/helpers/responses/models/operationResult"
import {UserRevenueDto} from "@/areas/referrals/dtos/userRevenueDto";

export class ReferralProxy {

    public async GetDownlines(from: Date, to: Date) : Promise<OperationResult<UserRevenueDto[]>> {
        const uri = '/users/referrals/downlines'

        try {
            const response = await axiosInstance.get<UserRevenueDto[]>(uri, {
                params: {
                    From: from,
                    To: to
                }
            });

            return Operations.ToSuccessResponse(response.data);

        } catch (error) {
            console.error("Error fetching downlines:", error);

            return Operations.ToErrorResponse("RequestFailure");
        }
    }
}