import axiosInstance from "@/axiosConfig";


export class CatalogueProxy {
    public async getCatalogue() {
        const uri = `/catalogue?productCode=SIMB`

        try {
            const response = await axiosInstance.get(uri)
            return response.data
        } catch(error) {
            console.error(error);
            return null;
        }
    }

    public async getCatalogueEntry(itemGuid: string) {
        const uri = `/catalogue/${itemGuid}`

        try{
            const response = await axiosInstance.get(uri)
    
            return response.data;
        }
        catch(error) {
            console.error(error);
            return null;
        }
    }
}