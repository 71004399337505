import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["value"]
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "text-red font-semibold font-montserrat py-1 w-full text-center items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("input", {
      type: "date",
      value: _ctx.formattedDate,
      class: "rounded-md p-4 rounded-md border border-black w-full text-center drop-shadow-xl text-l font-montserrat font-semibold",
      onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.updateDate && _ctx.updateDate(...args)))
    }, null, 40, _hoisted_1),
    (_ctx.error)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.error), 1)
        ]))
      : _createCommentVNode("", true)
  ], 64))
}